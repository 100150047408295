import React, { useEffect, useState } from 'react';

import ComboBox from '../../../../../components/ComboBox';
import CollapsableSection from '../../../../../components/CollapsibleSection';
import ButtonForm from '../../../../../components/ButtonForm';
import InputForm from '../../../../../components/InputForm';
import SelectableList from '../../../../../components/SelectableList';
import SelectionForm from '../../../../../components/SelectionForm';

import CheckIco from '../../../../../components/icons/CheckIco';
import TrashIco from '../../../../../components/icons/TrashIco';
import AddIco from '../../../../../components/icons/AddIco';

import Spinner from '../../../../../components/Spinner';

const ColumnField = ({ header }) => {
  return (
    <div className='flex justify-between items-center w-full mr-3'>
      <div className='flex basis-1/3 items-center gap-1'>
        <span>{header.alias}</span>
      </div>
      <span className='basis-1/3 text-gray-400'>{header.key}</span>
      <button className="" onClick={() => console.log(header)}>
        <TrashIco fill='red' />
      </button>
    </div>
  );
};

function HorizontalBarChartForm() {
  const bdConf = {
    process: { name: "Recepción MP", id: 2 },
    headers: [
      { key: "batch", displayName: "Lote", alias: "Lote", color: "bluesens", stacked: true },
      { key: "op", displayName: "Orden Prod.", alias: "OP", color: "bluesens", stacked: true },
      { key: "mp", displayName: "Materia Prima", alias: "MP", type: "dictarray", color: "bluesens" },
      { key: "weight", displayName: "Total", alias: "Peso", type: "numeric", color: "bluesens", stacked: true },
      { key: "date", displayName: "Fecha", alias: "Fecha", type: "date", color: "bluesens" },
    ],
    sortHeader: { "date": false },
    rowsNumber: 10
  }
  const [conf, setConf] = useState();
  const [verticalAxis, setVerticalAxis] = useState();
  const [horizontalAxis, setHorizontalAxis] = useState();
  const [stage,setStage] = useState("form");

  const selectAxisV = (selectedItems) => {
    if (bdConf.headers && selectedItems){
      const axisV = bdConf.headers.find( element => {return selectedItems.includes( element.alias || element.key)});
      setVerticalAxis(axisV)
    }
    
  }
  const selectAxisH = (selectedItems) => {
    if (bdConf.headers && selectedItems){
      const axisH = bdConf.headers.find( element => {return selectedItems.includes( element.alias || element.key)});
      setHorizontalAxis(axisH)
    }
    
  }

  useEffect( () => {
    if (stage === "form"){
      //cargar conf
      setConf(bdConf);
    }
    if (stage === "columnSection"){

    }
  }, [])
  return (
    <>
     {stage === "form" && (
      <>
        {conf && (
            <div className='flex flex-col gap-2 h-[35rem] relative overflow-auto'>
            <div className=' flex flex-col flex-grow gap-2'>
              <CollapsableSection title={"Datos"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' open={true} border={false}>
                <div className='flex flex-col gap-2'>
                  <div className='flex justify-between items-center'>
                    <ComboBox options={["Proceso 1", "Proceso 2"]} />
                    
                  </div>
                 
                    <CollapsableSection 
                      title={"Eje vertical"}
                      titleBgColor='bg-aliceblue'
                      titleTextColor='text-gray'
                      open={true}
                    >
                      <div className='w-full flex flex-col px-4'>
                        { verticalAxis && (
                          <ColumnField header={verticalAxis}/>
                        )}
                        { !verticalAxis && (
                          <div className=' mx-auto'>
                            <ButtonForm 
                              text={"Agregar campo"} 
                              Icon={() => {return (<AddIco fill='white'/>)}} 
                              enabled={true}
                              action={ () => setStage("setVerticalAxis")}
                            />
                          </div>
                        )}
                      </div>
                    </CollapsableSection>

                    <CollapsableSection
                      title={"Eje horizontal (datos numéricos)"}
                      titleBgColor='bg-aliceblue'
                      titleTextColor='text-gray'
                      open={true}
                    >
                      <div className='w-full flex flex-col px-4'>
                        { horizontalAxis && (
                          <ColumnField header={horizontalAxis}/>
                        )}
                        { !horizontalAxis && (
                          <div className=' mx-auto'>
                            <ButtonForm 
                              text={"Agregar campo"} 
                              Icon={() => {return (<AddIco fill='white'/>)}} 
                              enabled={true}
                              action={ () => setStage("setHorizontalAxis")}
                            />
                          </div>
                        )}
                      </div>
                    </CollapsableSection>
                  
                  <div className='-mb-5 pt-4'>
                    <SelectionForm text="Rango de tiempo" options={["Asignar por tablero"]} />
                  </div>
                  
                </div>
              </CollapsableSection>
              <CollapsableSection title={"Apariencia"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' border={false} open={true}>
                <InputForm text={"Nombre"} />
                <InputForm type={"number"} text={"Decimales"} />
                <InputForm type={"button"} text={"Estilo"} value={"Modificar estilo"} AppendClassName='text-left' />
              </CollapsableSection>
            </div>

            {/* Span fijo al final del contenedor */}
            <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
              <div className='flex w-full justify-end'>
                  <button className=''>
                    <CheckIco width='50px' height='50px'/>
                  </button>
              </div>
            </div>
          </div>
        )}
        {!conf && (
          <Spinner />
        )}
      </>
      
      
     )}
     {stage === "setVerticalAxis" && (
      <div className='flex flex-col gap-2 h-[25rem] relative overflow-auto '>
        <div className=' flex flex-col flex-grow gap-2'>
          <span className='font-semibold text-lg'>{conf.process.name}</span>
          <SelectableList items={bdConf.headers.filter(element => !element.type ).map(element => element.alias ? element.alias : element.key)} multiSelect={false} setItems={selectAxisV}/>
        </div>
        
        {/* Span fijo al final del contenedor */}
        <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
          <div className='flex w-full justify-end'>
            < ButtonForm text={"Guardar"} enabled={true} action={() => setStage('form')}/>
          </div>
        </div>
      </div>
     )}
     {stage === "setHorizontalAxis" && (
      <div className='flex flex-col gap-2 h-[25rem] relative overflow-auto '>
        <div className=' flex flex-col flex-grow gap-2'>
          <span className='font-semibold text-lg'>{conf.process.name}</span>
          <SelectableList items={bdConf.headers.filter(element => element.type === "numeric").map(element => element.alias ? element.alias : element.key)} multiSelect={false} setItems={selectAxisH}/>
        </div>
        
        {/* Span fijo al final del contenedor */}
        <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
          <div className='flex w-full justify-end'>
            < ButtonForm text={"Guardar"} enabled={true} action={() => setStage('form')}/>
          </div>
        </div>
      </div>
     )}
    </>
   
    
  );
}

export default HorizontalBarChartForm;
