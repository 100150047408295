import React from 'react';

const Modal = ({ isOpen, onClose, title, children, showCloseButton = true, size="w-3/5", colorTitle="bg-blue-300", customType=1}) => {  // Added showCloseButton prop for control the button corner
    if (!isOpen) return null; // Don't render if not open

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 ">
            <div className={"bg-white p-6 rounded-lg shadow-lg  overflow-y-auto " + size}>
                {customType === 1 && (
                    <div className={"flex items-center justify-between mb-4 w-full p-4 rounded-lg " + colorTitle}>
                        <h3 className="text-xl font-semibold">{title}</h3>
                        {showCloseButton && (  // Conditionally render close button
                            <button onClick={onClose}>
                                <img src={`${process.env.PUBLIC_URL}/svg/closeIco.svg`} alt="Cerrar" className="rounded-full p-1 bg-bluesens h-8 w-8" />
                            </button>
                        )}
                    </div>
                )}
                 {customType === 2 && (
                    <div className={"flex items-center justify-between mb-4 w-full p-2  " + colorTitle}>
                        <h3 className="text-lg text-white">{title}</h3>
                        {showCloseButton && (  // Conditionally render close button
                            <button onClick={onClose}>
                                <img src={`${process.env.PUBLIC_URL}/svg/closeIco.svg`} alt="Cerrar" className="rounded-full p-1 bg-bluesens h-8 w-8" />
                            </button>
                        )}
                    </div>
                 )}
                {children}
            </div>
        </div>
    );
};

export default Modal;
