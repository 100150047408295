import React from 'react'

function StacksIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}>
        <path d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z"/>
    </svg>
  )
}

export default StacksIco