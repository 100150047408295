import React, { useEffect, useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonForm from '../../../components/ButtonForm'
import GenericTable from '../../../components/GenericTable'
import AddEditParameter from './AddEditParameter'
import Modal from '../../../components/Modal'
import Spinner from '../../../components/Spinner'

//context
import { useProcesses } from '../../../providers/ProcessesContext'
import { useAuth } from '../../../providers/AuthContext'
//api
import { getProcessById } from '../../../services/apiProcess'
import { useNavigate } from 'react-router-dom'


let data = {
  header: [
    "Clave",
    "Alias",
    "Tipo de dato",
    "ACCIONES"
  ],
  body: [
    {"Clave": "batch", "Alias": "Lote 1", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 2", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 3", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 4", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 5", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 6", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 7", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 8", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "weight", "Alias": "Peso", "Tipo de dato": "numerico", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "date", "Alias": "Fecha", "Tipo de dato": "fecha", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 9", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 10", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 11", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 12", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 13", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 14", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 15", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 16", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 17", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 18", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 19", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 20", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 21", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 22", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 23", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 24", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 25", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 26", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 27", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 28", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 29", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 30", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 31", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 32", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    
  ],
  total: 34
}

function ConfigSection() {
  const navigate = useNavigate();
  const {selectedOption } = useProcesses();

  const [showForm, setShowForm] = useState(false);
  const [showConfForm, setShowConfForm] = useState(false);
  const [parameterToEdit, setParameterToEdit] = useState(undefined);
  const [process, setProcess] = useState();
  const [fetchingProcess, setFetchingProcess] = useState(true);
  const {auth} = useAuth();

  const toggleEditModal = (event) => {
    setShowForm(true);
  }
  const toggleConfirmationModal = (event) => {
    setShowConfForm(true);
  }
  const refreshProcess = () => {
    async function fetchProcess(){
      const process = await getProcessById(selectedOption, auth?.token);
      return process;
    }
    try {
      setFetchingProcess(true);
      fetchProcess().then(response => {
        console.log(console.log(response))
        setProcess(response);
        setFetchingProcess(false);
      }).catch(error => {
        navigate('/logout')
      })
    } catch (error) {
      console.log(error);
      setFetchingProcess(false);
    }
  }
  useEffect( () => {
    console.log("traer info del ", selectedOption);
    refreshProcess();
  }, [selectedOption]);
  
  return (
    <div className='w-full h-screen overflow-y-scroll bg-graysens'>
      {/* Modal de edicion */}
      <Modal
        isOpen={showForm}
        onClose={() => {
          setShowForm(false);
          setParameterToEdit(null);
        }}
        customType={2}
        colorTitle='bg-bluesens'
        title={
          parameterToEdit
            ? `Editar parámetro " ${parameterToEdit?.Clave} "`
            : "Crear parámetro"
        }
      >
        <AddEditParameter
          parameter={parameterToEdit}
        />
      </Modal>
      {/* Modal confirmación eliminar */}
      <Modal
        isOpen={showConfForm}
        onClose={() => {
          setShowConfForm(false);
          setParameterToEdit(null);
        }}
        customType={2}
        colorTitle='bg-red-600 '
        title={
          parameterToEdit
            ? `Eliminar parametro " ${parameterToEdit?.Clave} "`
            : "Eliminar parametro"
        }
      >
        <p className="mb-4">
          ¿Está seguro de eliminar este parámetro?
        </p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => {}}
          >
            Eliminar
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setShowConfForm(false)}
          >
            Cancelar
          </button>
        </div>
      </Modal>

      {!fetchingProcess && (
        <div className='w-[95%] mx-auto min-h-screen bg-white'>
          <div className='px-10 w-full pt-5'>
            <section id='proccess-name' className='border-b mb-1'>
              <div className='flex items-center w-100 justify-between'>
                <span className='text-xl '>Datos del proceso</span>
                <ButtonForm text={"Eliminar proceso" }/>
              </div>
              <div className='flex flex-col w-full justify-between'>
                <div className='w-80 pt-3'>
                  <InputForm 
                    type="text" id="proccessName" text="Nombre del proceso"
                    value={process.name}
                    onChange={(event) => {}}
                  />
                  
                </div>
                <div className='w-80 pb-3 '>
                  <ButtonForm text={"Guardar"} enabled={true}  />
                </div>
                
              </div>
              
            </section>
            <section id='proccess-parameters'>
              <div className='flex flex-col'>
              
                <div className='flex justify-between items-center my-2'>
                <span className='text-xl'>Parámetros</span>
                  <ButtonForm text={"Nuevo parámetro"} enabled={true} action={toggleEditModal} />
                </div>
                
                <GenericTable
                  data={data}
                  actions={{
                    "EDIT": (event) => {setParameterToEdit(data.body[event.currentTarget.id]); toggleEditModal(event);},
                    "DELETE":  (event) => {setParameterToEdit(data.body[event.currentTarget.id]); toggleConfirmationModal(event);} 
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      )}
      {fetchingProcess  && (
        <div className='w-full h-full flex items-center justify-center'><Spinner /></div>)}
      
    </div>
  )
}

export default ConfigSection