import React from 'react'

function DashboardIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#000000"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}
    >
        <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Z"/>
    </svg>
  )
}

export default DashboardIco