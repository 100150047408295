import React, { useState, useEffect } from "react";
function UserRow({ user, onEdit, onDelete, organizationsParent }) {
  const [organizationNames, setOrganizationNames] = useState([]);

  useEffect(() => {
    async function fetchOrganizationNames() {
      if (user.organizationIds && user.organizationIds.length > 0) {
        const organizationIds = user.organizationIds;
        const organizationNames = organizationIds.map(orgId => {
          const foundOrg = organizationsParent.find(org => org._id.toString() === orgId.toString()); 
          return foundOrg ? foundOrg.name : null; 
        }).filter(Boolean);
      
        setOrganizationNames(organizationNames);
      }
    }
    fetchOrganizationNames();
  }, [user.organizationIds, organizationsParent]);

  return (
    <tr className="border-b">
      <td className="px-4 py-2">{user.username}</td>
      <td className="px-4 py-2">{user.email}</td>
      <td className="px-4 py-2">
        {new Date(user.createdAt).toISOString().replace("T", " ").slice(0, -5)}
      </td>
      <td className="px-4 py-2">{organizationNames.join(", ") || "-"}</td>
      <td className="px-4 py-2">
        <button onClick={() => onEdit(user)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/editIco.svg`}
            alt="Editar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onDelete(user)}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt="Eliminar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
      </td>
    </tr>
  );
}

export default UserRow;
