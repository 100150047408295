import React, { useState } from 'react';

const CollapsibleSection = ({ 
  title, 
  children, 
  titleBgColor = 'bg-blue-500' , 
  titleTextColor = 'text-white', 
  open=false, 
  border=true,
  CustomTitleBar
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${border ? "border": "" } border-gray-300 rounded-md shadow-md`}>
      {/* Título */}
      <button
        className={`w-full flex justify-between items-center px-4 py-3 ${titleTextColor} text-left focus:outline-none ${titleBgColor}`}
        onClick={toggleSection}
      >
        {CustomTitleBar ?  (
          <CustomTitleBar />
        ): (
          <span>{title}</span>
        )}
        
        <svg
          className={`w-5 h-5 transition-transform duration-150 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {/* Contenido colapsable */}
      <div
        className={`transition-max-height duration-150 ease-in-out ${
          isOpen ? 'max-h-full overflow-visible ' : 'max-h-0 overflow-hidden '
        }`}
      >
        <div className="py-4 px-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleSection;
