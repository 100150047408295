import React from 'react'

function CheckIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}>
        <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
    </svg>
  )
}

export default CheckIco