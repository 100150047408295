import React from "react";

function OrganizationRow({ organization, onEdit, onDelete }) {
  return (
    <tr className="border-b">
      <td className="px-4 py-2">{organization.name}</td>
      {/* Show '-' if there is no description */}
      <td className="px-4 py-2">{organization.description || "-"}</td>
      {/* Show 0 if there are no devices */}
      <td className="px-4 py-2">{organization.usersCount || 0}</td>
      <td className="px-4 py-2">
        <div
          className="w-6 h-6 rounded-full"
          style={{ backgroundColor: organization.color }}
        ></div>
      </td>
      <td className="px-4 py-2">
        <button onClick={() => onEdit(organization)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/editIco.svg`}
            alt="Editar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onDelete(organization)}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt="Eliminar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
      </td>
    </tr>
  );
}

export default OrganizationRow;