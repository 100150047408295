import React from 'react';
import OrganizationRow from './OrganizationRow';

function OrganizationTable({ organizations, onEdit, onDelete, onSort, sortBy }) { 
  sortBy = JSON.parse(sortBy);
  const handleHeaderClick = (column) => {
      if (onSort) {
        onSort(column); // Call the parent component's sorting function
      }
    };

    return (
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th 
              onClick={() => handleHeaderClick('name')} 
              className={`cursor-pointer px-4 py-2 text-left w-1/6`}
            >
              Nombre {(sortBy.column === 'name' && (sortBy.isAscending ? '↑' : '↓'))|| '↑↓'}
            </th>
            <th 
              onClick={() => handleHeaderClick('description')} 
              className={`cursor-pointer px-4 py-2 text-left w-2/6` }
            >
              Descripción {(sortBy.column === 'description' && (sortBy.isAscending ? '↑' : '↓')) || '↑↓'}
            </th>
            <th 
              onClick={() => handleHeaderClick('usersCount')} 
              className={`cursor-pointer px-4 py-2 text-left`}
            >
              Cantidad de usuarios {(sortBy.column === 'usersCount' && (sortBy.isAscending ? '↑' : '↓')) || '↑↓'}
            </th>
            <th className="px-4 py-2 text-left">Color</th>
            <th className="px-4 py-2 text-left">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {organizations?.length > 0 ? (
            organizations.map((organization) => (
              <OrganizationRow
                key={organization._id}
                organization={organization}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center py-4">
                No hay organizaciones existentes
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
}

export default OrganizationTable;