import React from 'react';
import UserRow from './UserRow';

function UsersTable({ users, onEdit, onDelete, onSort, sortBy, organizationsParent }) {
  sortBy = JSON.parse(sortBy?sortBy:'{}');
  const handleHeaderClick = (column) => {
    if (onSort) {
      onSort(column);
    }
  };

  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th
            onClick={() => handleHeaderClick("username")}
            className={`cursor-pointer px-4 py-2 text-left w-1/6`}
          >
            Usuario{" "}
            {(sortBy.column === "username" && (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("email")}
            className={`cursor-pointer px-4 py-2 text-left w-2/6`}
          >
            Correo{" "}
            {(sortBy.column === "email" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("createdAt")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Fecha de creación{" "}
            {(sortBy.column === "createdAt" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th className="px-4 py-2 text-left">Organizaciones</th>
          <th className="px-4 py-2 text-left">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {users.length > 0 ? (
          users.map(user => (
            <UserRow 
              key={user._id}
              user={user} 
              onEdit={onEdit} 
              onDelete={onDelete} 
              organizationsParent={organizationsParent}
            />
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center py-4">No hay usuarios existentes</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default UsersTable;