import React, { useState, useRef, useEffect } from 'react';
import MoreUpIcon from './icons/MoreUpIcon';
import DotDropdownMenu from './DotDropdownMenu';
import EditIco from './icons/EditIco';
import DeleteIco from './icons/TrashIco';
import ContentCopyIco from './icons/ContentCopyIco';

const ResizableDraggableCard = ({ id, x, y, width, height, containerWidth, containerHeight, onDrag, onResize, title, children, onDuplicate, onDelete }) => {
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [resizeFromBottom, setResizeFromBottom] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [startSize, setStartSize] = useState({ width: 0, height: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);

  const options = [
    { name: "Duplicar", icon: ContentCopyIco, action: () => onDuplicate(id) },
    { name: "Editar", icon: EditIco, action: () => console.log("editar", id) },
    { name: "Eliminar", icon: DeleteIco, action: () => onDelete(id) }
  ];

  const handleMouseDown = (e) => {
    if (e.target.classList.contains('resize-handle-bottom')) {
      setResizing(true);
      setResizeFromBottom(true);
      setStartSize({ width, height });
      setStartPos({ x: e.clientX, y: e.clientY });
    } else if (e.target.classList.contains('resize-handle-corner') || e.target.closest('.resize-handle-corner')) {
      setResizing(true);
      setResizeFromBottom(false);
      setStartSize({ width, height });
      setStartPos({ x: e.clientX, y: e.clientY });
    } else if (e.target.classList.contains('card-header')) {
      setDragging(true);
      setStartPos({ x: e.clientX - x, y: e.clientY - y });
    }
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newX = e.clientX - startPos.x;
      const newY = e.clientY - startPos.y;
      onDrag(id, newX, newY);
    } else if (resizing) {
      if (resizeFromBottom) {
        const newHeight = Math.max(100, startSize.height + e.clientY - startPos.y);
        onResize(id, width, newHeight);
      } else {
        const newWidth = Math.max(100, startSize.width + e.clientX - startPos.x);
        const newHeight = Math.max(100, startSize.height + e.clientY - startPos.y);
        onResize(id, newWidth, newHeight);
      }
    }
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(false);
    }
    if (resizing) {
      setResizing(false);
      setResizeFromBottom(false);
    }
  };

  useEffect(() => {
    if (dragging || resizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [dragging, resizing]);

  return (
    <div
      ref={cardRef}
      className={`absolute overflow-clip bg-white border border-gray-300 shadow-md ${dragging || resizing ? 'select-none' : ''}`}
      style={{ left: x, top: y, width, height }}
      onMouseDown={handleMouseDown}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-header  font-semibold text-gray-600 p-2 cursor-move flex justify-between">
        {title}
        <DotDropdownMenu 
          options={options}
        /> 
      </div>
      <div className="h-full overflow-hidden">
        {children}
      </div>
      <div
        className="resize-handle-bottom absolute bottom-0 left-0 w-full h-4 cursor-s-resize"
      />
      <div
        className="resize-handle-corner absolute bottom-0 right-0 w-5 h-5 cursor-se-resize flex items-center justify-center transform rotate-[-270deg]"
        onMouseDown={handleMouseDown}
      >
        {isHovered && (
          <div className='pb-2 pl-2'>
            <MoreUpIcon width='15px' height='15px' fill='gray' />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResizableDraggableCard;
