import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
  // Leer el valor del localStorage
  const readValueFromLocalStorage = () => {
    try {
      const storedValue = localStorage.getItem(key);
      // Si existe, lo parseamos; de lo contrario, usamos el valor inicial
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.error(`Error leyendo la clave "${key}" del localStorage`, error);
      return initialValue;
    }
  };

  // Inicializa el estado con el valor del localStorage o el valor inicial
  const [storedValue, setStoredValue] = useState(readValueFromLocalStorage);

  // Sincroniza el localStorage cuando cambia el valor
  useEffect(() => {
    try {
      // Actualiza el localStorage con el nuevo valor
      localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error(`Error al guardar la clave "${key}" en el localStorage`, error);
    }
  }, [key, storedValue]);

  // Función para actualizar el valor
  const setValue = (value) => {
    try {
      // Si el valor es una función, invocarla (para soportar el setState en React)
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore); // Actualiza el estado
    } catch (error) {
      console.error(`Error al establecer la clave "${key}" en el localStorage`, error);
    }
  };

  // Devuelve el valor actual y la función para actualizarlo
  return [storedValue, setValue];
}

export default useLocalStorage;
