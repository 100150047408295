import React, { useState, useEffect } from "react";
import {
  getOrganizationById
} from "../../../services/apiOrganizations";

function RoleRow({ role, onEdit, onDelete }) {
  const [organizationNames, setOrganizationNames] = useState([]);

  useEffect(() => {
    async function fetchOrganizationNames() {
      if (role.organizationIds && role.organizationIds.length > 0) {
        try {
          const namesPromises = role.organizationIds.map(getOrganizationById);
          const organizations = await Promise.all(namesPromises);
          const names = organizations
            .filter(Boolean) // Filter out null values (if organization not found)
            .map(org => org.name);
          setOrganizationNames(names);
        } catch (error) {
          console.error("Error fetching organization names:", error);
        }
      }
    }
    fetchOrganizationNames();
  }, [role.organizationIds]);


  return (
    <tr className="border-b">
      <td className="px-4 py-2">{role.name}</td>
      <td className="px-4 py-2">{role.description || "-"}</td>
      <td className="px-4 py-2">{organizationNames.join(", ") || "-"}</td>
      <td className="px-4 py-2">
        <button onClick={() => onEdit(role)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/editIco.svg`}
            alt="Editar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onDelete(role)}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt="Eliminar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
      </td>
    </tr>
  );
}

export default RoleRow;