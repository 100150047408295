// src/routes.js
import React from 'react';
import { Route, Navigate , Routes} from 'react-router-dom';
import Login from './features/auth/pages/Login';
import Recovery from './features/auth/pages/Recovery';
import Profile from './features/users/pages/Profile';
import Dashboards from './features/dashboards/pages/Dashboards';
import { useAuth } from './providers/AuthContext';
import Logout from './features/auth/pages/Logout';
import Organizations from './features/organizations/pages/Organizations';
import Processes from './features/processes/pages/Processes';
import Users from './features/users/pages/Users';
import Roles from './features/roles/pages/Roles';
import Permissions from './features/permissions/pages/Permissions';
import Devices from './features/devices/pages/Devices';
import DeviceDetails from './features/devices/pages/DeviceDetails';
import VariableDetails from './features/variables/page/VariableDetails';

const PrivateRoute = ({ children }) => {
  const { isAuth } = useAuth();
  console.log(isAuth(), "yamando");
  return isAuth() ? children : <Navigate to="/login" />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/recovery" element={<Recovery />} />
      <Route path="/logout" element={<Logout />} />
      {/* Private routes*/}
      <Route path="/profile" element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      } />
      <Route path="/dashboard" element={
        <PrivateRoute>
          <Dashboards />
        </PrivateRoute>
      } />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="/organizaciones" element={
        <PrivateRoute>
          <Organizations />
        </PrivateRoute>
      } />
      <Route path="/processes/" element={
        <PrivateRoute>
          <Processes />
        </PrivateRoute>
      } />
      <Route path="/usuarios" element={
        <PrivateRoute>
          <Users />
        </PrivateRoute>
      } />
      <Route path="/roles" element={
        <PrivateRoute>
          <Roles />
        </PrivateRoute>
      } />
      <Route path="/permisos" element={
        <PrivateRoute>
          <Permissions />
        </PrivateRoute>
      } />
      <Route path="/dispositivos" element={
        <PrivateRoute>
          <Devices />
        </PrivateRoute>
      } />
      <Route path="/dispositivo/:deviceId" element={
        <PrivateRoute>
          <DeviceDetails />
        </PrivateRoute>
      } />
      <Route path="/dispositivo/:deviceId/variable/:variableId" element={
        <PrivateRoute>
          <VariableDetails />
        </PrivateRoute>
      } />
    </Routes>
  );
};

export default AppRoutes;
