import React, { createContext, useState, useContext, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useLocalStorage('auth', null); // Usar useLocalStorage para auth
  const [loadingAuth, setLoadingAuth] = useState(true); // Controla la carga inicial

  // Función de login
  const login = (token) => {
    setAuth({ token }); // Actualiza el auth en el localStorage y el estado
  };

  // Cargar datos de autenticación desde localStorage al montar el componente
  useEffect(() => {
    // Si ya hay datos en localStorage, establecemos el estado
    if (auth) {
      setAuth(auth);
    }
    setLoadingAuth(false); // La carga ha finalizado
  }, [auth]);

  // Función de logout
  const logout = () => {
    setLoadingAuth(true); // Mientras se limpia el estado
    setAuth(null); // Borra el auth en el localStorage y el estado
    setLoadingAuth(false); // Termina el proceso de logout
  };

  // Verificar si el usuario está autenticado
  const isAuth = () => {
    return auth && auth.token ? true : false;
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, isAuth, loadingAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
