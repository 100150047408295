import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/organizations'; // URL de organizationes

// Get all organizations (considering pagination, filtering and sorting)
export async function getOrganizations(page = 1, limit = 10, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true }), token) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy
      },
      headers:
        {Authorization: "Bearer "+token}
      ,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error getting organizations: ' + error.message);
  }
}

// Get all organizations
export async function getAllOrganizations(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error getting organizaciones: ' + error.message);
  }
}

// Get organization by ID
export async function getOrganizationById(orgId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${orgId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data; 
  } catch (error) {
    console.error(`Error fetching organization with ID ${orgId}:`, error);
    return null; 
  }
}

// Create a new organization
export async function createOrganization(newOrganization, token) {
  try {
    const response = await axios.post(API_BASE_URL, newOrganization,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error creating organization: ' + error.message);
  }
}

// Update an existing organization
export async function updateOrganization(updatedOrganization, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${updatedOrganization._id}`, updatedOrganization,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating organization: ' + error.message);
  }
}

// Delete an organization
export async function deleteOrganization(deletedOrganization, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deletedOrganization._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting organization: ' + error.message);
  }
}
