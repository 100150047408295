import React, { useState, useRef, useEffect } from 'react';
import CalendarIco from './icons/CalendarIco';

// Función para generar los días del mes
const generateCalendar = (year, month) => {
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  let daysArray = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    daysArray.push(null); // Espacios vacíos antes del primer día
  }

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(day);
  }

  return daysArray;
};

const months = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
  'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

const DatePicker = ({ onDateChange, text }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState({
    hours: new Date().getHours(),
    minutes: new Date().getMinutes(),
  });
  const [showYearSelector, setShowYearSelector] = useState(false);
  const datePickerRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const yearsArray = Array.from(
    { length: 11 }, // 11 para incluir el año actual + 10 años atrás
    (_, i) => currentYear - i
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateClick = (day) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day,
      selectedTime.hours,
      selectedTime.minutes
    );
    setSelectedDate(newDate);
    setShowCalendar(false);
    if (onDateChange) onDateChange(newDate);
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    );
  };

  const handleYearClick = () => {
    setShowYearSelector(!showYearSelector);
  };

  const handleYearSelect = (year) => {
    setCurrentDate(new Date(year, currentDate.getMonth()));
    setShowYearSelector(false);
  };

  const handleTimeChange = (type, value) => {
    setSelectedTime((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const formattedSelectedDate = selectedDate
    ? `${selectedDate.toLocaleDateString()} ${selectedTime.hours
      .toString()
      .padStart(2, '0')}:${selectedTime.minutes.toString().padStart(2, '0')}`
    : 'Selecciona una fecha';

  const daysArray = generateCalendar(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

  const prevMonthAbbr = months[(currentDate.getMonth() - 1 + 12) % 12].slice(0, 3);
  const nextMonthAbbr = months[(currentDate.getMonth() + 1) % 12].slice(0, 3);

  return (
    <div className="relative inline-block" ref={datePickerRef}>
      <label 
        className={
          `text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:left-4 ${showCalendar || showYearSelector ? "text-blue-900": ""} peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:font-medium absolute`
        }
      >
        {text}
      </label>
      <div
        className={`border-2 border-gray-300 py-3.5 px-4 w-full cursor-pointer rounded-md text-gray-900 bg-transparent ${ showCalendar || showYearSelector ? "outline-none ring-0 border-blue-900" : ""} peer`}
        onClick={() => setShowCalendar(!showCalendar)}
      >
        {formattedSelectedDate}
      </div>
      {showCalendar && (
        <div className="absolute mt-2 p-4 bg-white border rounded shadow-lg z-10">
          {/* Navegación entre meses y años */}
          <div className="flex justify-between items-center mb-2">
            <button onClick={handlePrevMonth} className="text-blue-500 mx-1">
              {prevMonthAbbr}
            </button>
            <span
              className="font-semibold cursor-pointer"
              onClick={handleYearClick}
            >
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </span>
            <button onClick={handleNextMonth} className="text-blue-500 mx-1">
              {nextMonthAbbr}
            </button>
          </div>

          {/* Selección de años en lugar de lista desplegable */}
          {showYearSelector ? (
            <div className="grid grid-cols-3 gap-2 mb-4" style={{ width: '300px' }}>
              {yearsArray.map((year) => (
                <button
                  key={year}
                  className={`p-2 border rounded ${
                    year === currentDate.getFullYear() ? 'bg-bluesens text-white' : 'bg-white hover:bg-blue-100'
                  } `}
                  onClick={() => handleYearSelect(year)}
                >
                  {year}
                </button>
              ))}
            </div>
          ) : (
            <>
              {/* Calendario de días */}
              <div className="grid grid-cols-7 gap-2">
                {daysOfWeek.map((day) => (
                  <div key={day} className="text-center font-semibold">
                    {day}
                  </div>
                ))}
                {daysArray.map((day, index) => (
                  <div
                    key={index}
                    className={`text-center p-2 cursor-pointer ${
                      day
                        ? 'rounded'
                        : 'bg-transparent pointer-events-none'
                    } ${
                      day === selectedDate.getDate() ? 'bg-bluesens text-white' : 'hover:bg-blue-100 '
                    }`}
                    onClick={() => day && handleDateClick(day)}
                  >
                    {day}
                  </div>
                ))}
              </div>

              {/* Selector de hora debajo del calendario, oculto si está seleccionando año */}
              {!showYearSelector && (
                <div className="flex justify-center items-center space-x-2 mt-4">
                  <div>
                    <label className="block text-sm text-gray-500">Hora</label>
                    <input
                      type="number"
                      min="0"
                      max="23"
                      value={selectedTime.hours}
                      onChange={(e) => handleTimeChange('hours', Math.min(23, Math.max(0, parseInt(e.target.value) || 0)))}
                      className="border p-1 rounded w-16"
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-gray-500">Minutos</label>
                    <input
                      type="number"
                      min="0"
                      max="59"
                      value={selectedTime.minutes}
                      onChange={(e) => handleTimeChange('minutes', Math.min(59, Math.max(0, parseInt(e.target.value) || 0)))}
                      className="border p-1 rounded w-16"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <button
        type="button"
        className="absolute right-4 top-4 text-gray-600"
      >
        <CalendarIco fill={ showCalendar || showYearSelector ? 'bluesens' : "darkgray"}/>
      </button>
    </div>
  );
};

export default DatePicker;
