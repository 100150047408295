import React, { useState, useEffect } from "react";
import SearchBar from "../../../components/SearchBar";
import TextInput from "../../../components/TextInput";
import { useAuth } from "../../../providers/AuthContext";

import { getUserByUsername, getUserByEmail } from "../../../services/apiUsers";
import { getAllRoles } from "../../../services/apiRoles";
import { useNavigate } from "react-router-dom";


function UserForm({ onSubmit, user, organizationsParent }) {
  const [username, setUsername] = useState(user?.username || "");
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const {auth} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    function generateSecurePassword() {
      const length = 12;
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
      let password = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      return password;
    }

    const newPassword = generateSecurePassword();
    setPassword(newPassword);
  }, []);

  const [currentStage, setCurrentStage] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const totalStages = !user ? 4 : 3;
  const stageTitles = [
    "Información General",
    "Selección de Organización",
    "Selección de Rol",
    "Enviar Invitación",
  ];
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState(
    user?.organizationIds || []
  );
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState(user?.rolIds || []);

  useEffect(() => {
    async function fetchOrganizationsAndRoles() {
      setOrganizations(organizationsParent); //From the father, re-use previous query
      getAllRoles(auth?.token).then(rolesData => {
        setRoles(rolesData);
      }).catch( error => 
        navigate('/logout')
      );
      
    }
    fetchOrganizationsAndRoles(auth?.token);
  }, [organizationsParent]);

  const handleOrganizationToggle = (organizationId) => {
    setSelectedOrganizations([organizationId]); // Set the selected permission directly
  };

  const handleRoleToggle = (roleId) => {
    setSelectedRoles([roleId]); // Set the selected permission directly
  };

  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");

  useEffect(() => {
    setIsFormValid(
      username !== "" &&
        !errorMessageUsername &&
        !isValidEmail(username) &&
        email !== "" &&
        !errorMessageEmail &&
        isValidEmail(email)
    );
  }, [username, errorMessageUsername, email, errorMessageEmail]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    let info = {
      username,
      email,
      password,
      organizationIds: selectedOrganizations,
      rolIds: selectedRoles,
    };
    onSubmit({ ...user, ...info });
  };

  const handleNext = (event) => {
    event.preventDefault();
    setCurrentStage(currentStage + 1);
    setSearchTerm("");
  };

  const handlePrevious = () => {
    setCurrentStage(currentStage - 1);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    async function validateUsernameAndEmail() {
      if (username) {
        getUserByUsername(username, auth?.token).then(found_user => {
          const exist = typeof found_user !== "undefined";
          if (isValidEmail(username))
            setErrorMessageUsername("El nombre de usuario no debe ser un correo");
          else if (!isNaN(username)) {
            setErrorMessageUsername(
              "El nombre de usuario no puedo contener solo números"
            );
          } else if (
            !exist ||
            username.toLowerCase() === user?.username.toLowerCase()
          )
            setErrorMessageUsername("");
          else {
            setErrorMessageUsername("Este nombre de usuario ya esta en uso");
          }
        }).catch( error => 
          navigate('/logout')
        );
        
      } else {
        setErrorMessageUsername("");
      }
      if (email) {
        getUserByEmail(email, auth?.token).then(found_user => {
          const exist = typeof found_user !== "undefined";
          if (exist && email.toLowerCase() !== user?.email.toLowerCase()) {
            setErrorMessageEmail("El correo ya esta en uso");
          } else {
            setErrorMessageEmail("");
          }
        }).catch(error => 
          navigate('/logout')
        );
        
      } else {
        setErrorMessageEmail("");
      }
    }
    validateUsernameAndEmail();
  }, [username, user, email]);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredOrganizations = organizations.filter((organization) =>
    organization.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [copySuccessUsername, setCopySuccessUsername] = useState(false);
  const [copySuccessPassword, setCopySuccessPassword] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        if (text === username) {
          setCopySuccessUsername(true);
          setTimeout(() => setCopySuccessUsername(false), 1500); // Reset after 1.5 seconds
        } else if (text === password) {
          setCopySuccessPassword(true);
          setTimeout(() => setCopySuccessPassword(false), 1500);
        }
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-y-4"
    >
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-bluesens bg-blue-200">
              Paso {currentStage + 1}
            </span>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full">
              {stageTitles[currentStage]}
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-bluesens">
              {Math.round(((currentStage + 1) / totalStages) * 100)}%
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div
            style={{ width: `${((currentStage + 1) / totalStages) * 100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-bluesens"
          ></div>
        </div>
      </div>

      {currentStage === 0 && (
        <>
          <TextInput
            label="Nombre de Usuario"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value.trim().replace(/\s/g, ''))}
            error={errorMessageUsername}
          />

          <TextInput
            label="Correo"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={
              email === "" ||
              isValidEmail(email) ||
              email.toLowerCase() === user?.email.toLowerCase()
                ? errorMessageEmail
                : "Correo no válido"
            }
          />
        </>
      )}

      {currentStage === 1 && (
        <div>
          <div className="">
            <div className="mb-4 flex gap-x-4 items-center">
              <SearchBar
                type="search"
                id="search-bar-organization-userForm"
                text={`Buscar Organización`}
                onChange={handleSearchChange}
                value={searchTerm}
              />
              <span className="text-l font-bold	uppercase">{`${filteredOrganizations.length} Disponible(s)`}</span>
            </div>
            <div className="grid grid-cols-2 gap-4 max-h-[12rem] overflow-y-auto">
              {filteredOrganizations.map((organization) => (
                <button
                  key={organization._id}
                  type="button"
                  onClick={() => handleOrganizationToggle(organization._id)}
                  className={`px-4 py-2 rounded-md ${
                    selectedOrganizations.includes(organization._id)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {organization.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {currentStage === 2 && (
        <div>
          <div className="">
            <div className="mb-4 flex gap-x-4 items-center">
              <SearchBar
                type="search"
                id="search-bar-roles-userForm"
                text={`Buscar Rol`}
                onChange={handleSearchChange}
                value={searchTerm}
              />
              <span className="text-l font-bold	uppercase">{`${filteredRoles.length} Disponible(s)`}</span>
            </div>
            <div className="grid grid-cols-2 gap-4 max-h-[12rem] overflow-y-auto">
              {filteredRoles.map((role) => (
                <button
                  key={role._id}
                  type="button"
                  onClick={() => handleRoleToggle(role._id)}
                  className={`px-4 py-2 rounded-md ${
                    selectedRoles.includes(role._id)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {role.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {currentStage === 3 && !user && (
        <div className="flex flex-col items-center -my-4">
          <div className="text-gray-500 mb-4">
            <div className="">Asunto</div>
            <div className="border-solid border-2 rounded p-3">
              Te damos la bienvenida a Senscloud.
            </div>
            <div className="">Mensaje:</div>
            <div className="border-solid border-2 rounded p-3">
              <p>Hola, te informamos que tu cuenta ha sido creada con éxito.</p>
              <p>A continuación, te adjuntamos información importante:</p>
              <div className="flex justify-between gap-x-2 border-dashed border-b-2 py-2">
                <div className="font-bold text-bluesens">
                  Enlace de la plataforma:
                </div>{" "}
                <div className="font-bold">{""}</div>
              </div>
              <div className="flex justify-between gap-x-2 border-dashed border-b-2 py-2 relative">
                <div className="font-bold text-bluesens">Usuario:</div>
                <div className="font-bold flex items-center bg-gray-200 rounded p-1">
                  {username}
                  <button
                    type="button"
                    onClick={() => copyToClipboard(username)}
                    className="ml-2 p-1 rounded-full bg-white hover:bg-gray-300 focus:outline-none"
                  >
                    <img
                      className="w-4 h-4"
                      alt="dashboards"
                      src={`${process.env.PUBLIC_URL}/svg/contentCopy.svg`}
                    />
                  </button>
                  {copySuccessUsername && (
                    <span className="absolute right-0 -top-2 text-green-500 text-xs">
                      Copiado!
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-x-2 border-dashed border-b-2 py-2">
                <div className="font-bold text-bluesens">Rol:</div>
                <div className="font-bold">{`${selectedRoles
                  .map(
                    (selectedRoleID) =>
                      roles.find((role) => role._id === selectedRoleID).name
                  )
                  .join(", ")}`}</div>
              </div>
              <div className="flex justify-between gap-x-2 border-dashed border-b-2 py-2 relative">
                <div className="font-bold text-bluesens">Contraseña:</div>
                <div className="font-bold flex items-center bg-gray-200 rounded p-1">
                  {password}
                  <button
                    type="button"
                    onClick={() => copyToClipboard(password)}
                    className="ml-2 p-1 rounded-full bg-white hover:bg-gray-300 focus:outline-none"
                  >
                    <img
                      className="w-4 h-4"
                      alt="dashboards"
                      src={`${process.env.PUBLIC_URL}/svg/contentCopy.svg`}
                    />
                  </button>
                  {copySuccessPassword && (
                    <span className="absolute right-0 -top-2 text-green-500 text-xs">
                      Copiado!
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-x-2 border-dashed border-b-2 py-2">
                <div className="font-bold text-bluesens">Organización:</div>{" "}
                <div className="font-bold">{`${selectedOrganizations
                  .map(
                    (selectedOrgID) =>
                      organizations.find((org) => org._id === selectedOrgID)
                        .name
                  )
                  .join(", ")}`}</div>
              </div>
              <div>No es necesario que respondas este mensaje.</div>
            </div>
          </div>
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="w-full flex justify-between items-center">
        <button
          type="button"
          onClick={handlePrevious}
          className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded disabled:opacity-0 disabled:pointer-events-none"
          disabled={currentStage === 0}
        >
          Previo
        </button>
        <div>
          <p className="text-gray-500">
            Paso {currentStage + 1} de {totalStages}
          </p>
        </div>
        {currentStage !== totalStages - 1 ? (
          <button
            type="button"
            onClick={handleNext}
            id="next-btn-role-form"
            className={`bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded disabled:opacity-0 disabled:pointer-events-none ${
              currentStage === 0 ? "disabled:opacity-50" : ""
            } 
                `}
            disabled={!isFormValid}
          >
            Siguiente
          </button>
        ) : (
          <button
            type="submit"
            id="submit-btn-role-form"
            className={`bg-bluesens text-white font-bold hover:scale-125 transition-transform py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
            disabled={!isFormValid}
          >
            <span className="animate-pulse">Finalizar</span>
          </button>
        )}
      </div>
    </form>
  );
}

export default UserForm;
