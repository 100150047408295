import React, { useState } from 'react';
import ArrowDropDown from './icons/ArrowDropDown';

const SelectionForm = ({ options =[] , id,  text, error="", value=undefined, onChange, AppendClassName=""}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full mb-5 group">
      <input
        type='text'
        onClick={toggleDropdown}
        className={
          (error ?
          "cursor-pointer block py-3.5 px-4 w-full text-red_error bg-transparent border-2 border-red_error rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-red_error peer":  
          "cursor-pointer block py-3.5 px-4 w-full text-gray-900 bg-transparent border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer") + 
          " " + AppendClassName
        }
        id={id}
        name={id}
        placeholder=" "
        value={selectedOption}
        readOnly={true}
      >
       
      </input>
      <label 
        htmlFor={id}
        className={
          error ? 
          "peer-focus:font-medium absolute  text-red_error duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-red_error bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6": 
          "peer-focus:font-medium absolute  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-blue-900 bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        }
      >
        {text}
      </label>
      <button
          type="button"
          className="absolute right-4 top-4 text-gray-600"
          onClick={toggleDropdown}
        >
          <ArrowDropDown />
        </button>
      {isOpen && (
        <ul 
          className="absolute z-10 w-full text-gray-900  bg-white border-2 border-gray-300 rounded-md mt-1 overflow-hidden transition-all duration-300 transform origin-top scale-y-0"
          style={{ transform: isOpen ? 'scaleY(1)' : 'scaleY(0)' }}
        >
          {options.map((option, index) => (
            <li 
              key={index} 
              onClick={() => handleOptionClick(option)} 
              className="cursor-pointer py-3.5 px-4 -ml-10 list-none hover:bg-gray-200"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectionForm;
