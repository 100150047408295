import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + "/permissions"; // URL de permisos

// Get all permissions (considering pagination, filtering and sorting)
export async function getPermissions(
  page = 1,
  limit = 10,
  searchTerm = "",
  sortBy = JSON.stringify({ column: "createdAt", isAscending: true },),
  token
) {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        search: searchTerm,
        currentPage: page,
        documentsPerPage: limit,
        sortBy,
      },
      headers: {Authorization: "Bearer "+token}
    });
    return response.data;
  } catch (error) {
    throw new Error("Error getting permissions: " + error.message);
  }
}

// Get all permissions 
export async function getAllPermissions(token) {
  try {
    const response = await axios.get(API_BASE_URL+'/all',
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error getting permissions: " + error.message);
  }
}

// Get a single permission by ID
export async function getPermission(permissionId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${permissionId}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error getting permission: " + error.message);
  }
}

// Create a new permission
export async function createPermission(newPermission, token) {
  try {
    const response = await axios.post(API_BASE_URL, newPermission,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error creating permission: " + error.message);
  }
}

// Update an existing permission
export async function updatePermission(updatedPermission, token) {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/${updatedPermission._id}`,
      updatedPermission,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error updating permission: " + error.message);
  }
}

// Delete an permission
export async function deletePermission(deletedPermission, token) {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/${deletedPermission._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error deleting permission: " + error.message);
  }
}
