import React, { useState } from 'react'
import InputForm from '../../../../components/InputForm'
import SelectionForm from '../../../../components/SelectionForm'
import CheckIco from '../../../../components/icons/CheckIco'

function DashboardForm({saveDashboard}) {


  return (
    <div className='flex flex-col justify-between'>
      <div className='flex flex-col mb-32'>
        <InputForm text={"Nombre"}/>
        <InputForm type={"button"} text={"Rango de tiempo predeterminado"} value={"Hoy"} AppendClassName='text-left' />
        <SelectionForm text="Formato de fecha" options={["07-30-2024 09:28:41"]} />
      </div>
      <div className='w-full flex justify-end'>
        <button className='' onClick={() => saveDashboard()}>
          <CheckIco width='50px' height='50px'/>
        </button>
      </div>
    </div>
  )
}

export default DashboardForm