import React from 'react'

function MoreUpIcon({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill} >
      <path d="M680-320v-360H320v-80h440v440h-80ZM480-120v-360H120v-80h440v440h-80Z"/>
    </svg>
  )
}

export default MoreUpIcon