import React, { useState } from 'react';
export default function InputForm({type, text, id, error="", required = false, value=null, onChange=() =>{}, enabled=true, AppendClassName="", Icon}) {
  const [inputType, setInputType] = useState(type);
  

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const visibilityIcon = inputType === 'password' 
    ? `${process.env.PUBLIC_URL}/svg/visibility_off.svg`
    : `${process.env.PUBLIC_URL}/svg/visibility.svg`;

  return (
    <div className={"relative z-0 w-full mb-5 group" +( error ? " animate-shake": "")}>
      <input 
        type={inputType}
        name={id}
        id={id}
        defaultValue={value}
        className={
          (error ?
          "block py-3.5 px-4 w-full text-red_error bg-transparent border-2 border-red_error rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-red_error peer":  
          "block py-3.5 px-4 w-full text-gray-900 bg-transparent border-2 border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-0 focus:border-blue-900 peer") + 
          " " + AppendClassName
        }
        placeholder=" "
        required={required}
        onChange={onChange}
        disabled={!enabled}
      />
      <label 
        htmlFor={id} 
        className={
          error ? 
          "peer-focus:font-medium absolute  text-red_error duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-red_error bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6": 
          "peer-focus:font-medium absolute  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] peer-focus:left-4 peer-focus:text-blue-900 bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        }
      >
        {text}
      </label>
      {Icon && type!=="password" && (
        <div
          className="absolute right-4 top-4 text-gray-600"
        >
          <Icon />
        </div>
      )}
      {type === 'password' && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-4 top-4 text-gray-600"
        >
          <img 
            src={visibilityIcon} 
            alt="Toggle visibility" 
            className="w-6 h-6"
          />
        </button>
      )}
      {error && (
        <div className='relative pb-1'>
          <span className='px-1  absolute text-red_error'>
            {error}
          </span>
        </div>
        
      )}
    </div>
  )
}
