import React from 'react'

function HorizontalBarIco({height="24px", width="24px", viewBox="0 -0 35 35", fill="#020317"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}>
        <g>
          <rect x="2" y="1" fill={fill} width="1" height="32"/>
          <rect x="2" y="32" fill={fill} width="31" height="1"/>
          <rect x="6" y="3" fill={fill} width="8" height="5"/>
          <rect x="6" y="10" fill={fill} width="24" height="5"/>
          <rect x="6" y="17" fill={fill} width="14" height="5"/>
          <rect x="6" y="24" fill={fill} width="10" height="5"/>
        </g>
    </svg>
  )
}

export default HorizontalBarIco