import React, { useState, useEffect } from "react";
import ConfirmButton from "../../../components/ConfirmButton";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import { getDeviceByName } from "../../../services/apiDevices";
import SearchBar from "../../../components/SearchBar";
import { getAllOrganizations } from "../../../services/apiOrganizations";
import { useAuth } from "../../../providers/AuthContext";
import { useNavigate } from "react-router-dom";

function DeviceForm({ onSubmit, device }) {
  const {auth} = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const typeOptions = ["Default", "GPS"];

  const [errorMessageName, setErrorMessageName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(device?.organizationId || '');

  useEffect(() => {
    async function fetchOrganizations() {
      getAllOrganizations(auth?.token).then(organizationsData => {
        setOrganizations(organizationsData);
      }).catch(error =>
        navigate('/logout')
      );
      
    }
    fetchOrganizations();
  }, []);

  useEffect(() => {
    setIsFormValid((name !== "" && !errorMessageName) || device);
  }, [name, errorMessageName, device]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let info = { name, type, organizationId: selectedOrganization };
    const filteredInfo = Object.fromEntries(
      Object.entries(info).filter(
        ([_, value]) => value !== "" && value !== undefined && value !== null
      )
    );

    onSubmit({ ...device, ...filteredInfo });
  };

  useEffect(() => {
    async function validateName() {
      if (name) {
        getDeviceByName(name, auth?.token).then(found_device => {
          const exist = typeof found_device !== "undefined";
          if (!exist || name.toLowerCase() === device?.name.toLowerCase())
            setErrorMessageName("");
          else {
            setErrorMessageName("Este nombre de dispositivo ya esta en uso");
          }
        });
      } else {
        setErrorMessageName("");
      }
    }
    validateName();
  }, [name, device]);

  const handleOrganizationToggle = (organizationId) => {
    selectedOrganization === organizationId ? setSelectedOrganization('') : setSelectedOrganization(organizationId);
    console.log(selectedOrganization);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredOrganizations = organizations.filter((organization) =>
    organization.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-y-8"
    >
      {device?.isAssign ? (
        <div>
          <div className="">
            <div className="mb-4 flex gap-x-4 items-center">
              <SearchBar
                type="search"
                id="search-bar-organization-userForm"
                text={`Buscar Organización`}
                onChange={handleSearchChange}
                value={searchTerm}
              />
              <span className="text-l font-bold	uppercase">{`${filteredOrganizations.length} Disponible(s)`}</span>
            </div>
            <div className="grid grid-cols-2 gap-4 max-h-[12rem] overflow-y-auto">
              {filteredOrganizations.map((organization) => (
                <button
                  key={organization._id}
                  type="button"
                  onClick={() => handleOrganizationToggle(organization._id)}
                  className={`px-4 py-2 rounded-md flex items-center gap-8 ${
                    selectedOrganization.includes(organization._id)
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                  title="Asignar Organización"
                >
                  <img
                    src={organization.logo ? organization.logo : `${process.env.PUBLIC_URL}/svg/organizationIco.svg`}
                    alt="Logo ORG"
                    className="w-8 h-8 object-contain rounded-full object-cover bg-white"
                  />
                  {organization.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <TextInput
            label="Nombre del dispositivo"
            id="device_name"
            value={name}
            onChange={(e) => setName(e.target.value.trim().replace(/\s/g, ""))}
            error={errorMessageName}
          />

          <SelectInput
            label="Tipo"
            id="device_type"
            options={typeOptions.slice(1)}
            value={type}
            onChange={(e) => setType(e.target.value)}
            placeholder={typeOptions[0]}
          />
        </>
      )}

      <ConfirmButton isConfirmed={isFormValid} />
    </form>
  );
}

export default DeviceForm;
