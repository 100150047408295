import React from "react";

const SearchBar = ({ value, onChange, AppendClassName, id="search", text }) => {
    return (
        <div className="relative z-0 w-1/2 group">
            <input
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                className={`
                    block py-2.5 px-4 w-full text-gray-900 bg-transparent border-2 
                    border-gray-300 rounded-md appearance-none focus:outline-none 
                    focus:ring-0 focus:border-blue-900 peer ${AppendClassName}
                `}
                placeholder=" "
            />

            <label
                htmlFor={id}
                className={`
                    peer-focus:font-medium absolute text-gray-500 duration-300 transform -translate-y-4 scale-75 
                    top-3 left-4 origin-[0] peer-focus:left-4 peer-focus:text-blue-900 bg-white 
                    px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4
                    hover:cursor-pointer
                `}
            >
                {text}
            </label>

            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <img
                    src={`${process.env.PUBLIC_URL}/svg/searchIco.svg`}
                    alt="Search icon"
                    className="w-4 h-4"
                />
            </div>
        </div>
    );
};

export default SearchBar;
