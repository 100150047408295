import React, { useEffect } from 'react'
import { useAuth } from '../../../providers/AuthContext'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Logout() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();

  useEffect ( () => {
    console.log(auth)
    if (auth?.token){
      //realizar login al backend
      axios.post(process.env.REACT_APP_BACKEND_URL + '/logout',
        {
          token: auth.token
        },
      {
        headers: {
          Authorization: "Bearer " + auth.token
        }
      }).then(response => {
          console.log(response)
          if (response.status === 200){
            logout();
            navigate('/login');
          }
        }).catch(error => {
          console.log(error.response.data.message)
          logout();
          navigate('/login');
        });
    }
    else{
      logout();
      navigate('/login');
    }
  }, [auth, logout, navigate]);

  return (
    <></>
  );
}
