import React, { useState, useRef, useEffect } from 'react';
import MoreVertIco from './icons/MoreVertIco';

const DotDropdownMenu = ({ options = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  // Cierra el menú si se hace clic fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="relative inline-block text-left">
      <div onClick={toggleMenu} className="hover:cursor-pointer">
        <MoreVertIco />
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map((element, index) => (
              <button
                key={index}
                className="w-full flex text-left items-center gap-2 px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={element.action}
              >
                {element.icon && (<element.icon width={"20px"} height={"20px"} fill={"darkgray"} />)}
                <span>{element.name}</span>              
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DotDropdownMenu;
