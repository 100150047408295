import React, { useState, useEffect, useRef } from 'react';
// Asegúrate de importar correctamente el icono de menú desde tu librería o archivo local
import MenuIcon from '../../../../components/icons/MenuIcon';
import SearchIco from '../../../../components/icons/SearchIco';
import InputForm from '../../../../components/InputForm';
import ButtonForm from '../../../../components/ButtonForm';
import TrashIco from '../../../../components/icons/TrashIco';
import EditIco from '../../../../components/icons/EditIco';
import SkipPreviousIco from '../../../../components/icons/SkipPreviousIco';
import SkipNextIco from '../../../../components/icons/SkipNextIco';
import ArrowBack from '../../../../components/icons/ArrowBack';
import ArrowForward from '../../../../components/icons/ArrowForward';

import ComboBox from '../../../../components/ComboBox';
import Modal from '../../../../components/Modal';
import { useWidgets } from '../../../../providers/WidgetContext';
import Toaster from '../../../../components/Toaster';

import DashboardForm from './DashboardForm';

function Pagination({ total, currentPage, maxPaginationPages, onChange }) {
  const totalPages = Math.ceil(total / maxPaginationPages);
  
  function getPagination(c, m, g) {
    // Si el número máximo de números a mostrar es mayor o igual al total de páginas, mostramos todas las páginas
    if (g >= m) {
      return Array.from({ length: m }, (_, i) => i + 1);
    }
    let start, end;
    // Si la página actual está en el principio
    if (c <= Math.floor(g / 2)) {
      start = 1;
      end = g;
    } 
    // Si la página actual está cerca del final
    else if (c + Math.floor(g / 2) >= m) {
      start = m - g + 1;
      end = m;
    } 
    // Si la página actual está en el medio
    else {
      start = c - Math.floor(g / 2);
      end = c + Math.floor(g / 2);
    }
    // Generamos el arreglo de números de página
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  let arrayPages = getPagination(currentPage, totalPages, maxPaginationPages);

  return (
    <div className='flex gap-1'>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${currentPage !== 1 ? "hover:bg-graysens" : "cursor-not-allowed"}`} onClick={event => onChange(1)}>
        <SkipPreviousIco fill={currentPage !== 1 ? "gray" : undefined} />
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${currentPage !== 1 ? "hover:bg-graysens" : "cursor-not-allowed"}`} onClick={event => onChange(currentPage - 1 <= 1 ? 1 : currentPage - 1)}>
        <ArrowBack height='16px' width='16px' fill={currentPage !== 1 ? "gray" : undefined} />
      </button>
      {arrayPages.map((element, index) => (
        <button key={index}
          className={`mx-0.5 rounded-md min-w-6 h-6 ${element === currentPage ? 'bg-bluesens text-white' : 'bg-white hover:bg-graysens'}`} value={element} onClick={event => onChange(event.target.value)}
        >
          {element}
        </button>
      ))}

      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${currentPage !== totalPages ? "hover:bg-graysens" : "cursor-not-allowed"}`} onClick={event => onChange(currentPage + 1 >= totalPages ? totalPages : currentPage + 1)}>
        <ArrowForward height='16px' width='16px' fill={currentPage !== totalPages ? "gray" : undefined} />
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${currentPage !== totalPages ? "hover:bg-graysens" : "cursor-not-allowed"}`} onClick={event => onChange(totalPages)}>
        <SkipNextIco fill={currentPage !== totalPages ? "gray" : undefined} />
      </button>
    </div>
  );
}

const DashboardMenu = () => {
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const { dashboardList, setDashboard } = useWidgets();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsNumber, setRowsNumber] = useState(5);
  const [newDashboardModal, setNewDashboardModal] = useState(false);
  const menuRef = useRef(null);

  const [toasts, setToasts] = useState([]);

  const addToast = (message, type, duration) => {
    const id = Date.now();
    setToasts([...toasts, { id, message, type, duration }]);
  };

  const removeToast = (id) => {
    setToasts(toasts.filter(toast => toast.id !== id));
  };

  const saveDashboard = () => {
    toggleNewDashboardModal();
    addToast('Dashboard creado', 0)
  }

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsBoxOpen(false);
    }
  };

  useEffect(() => {
    if (isBoxOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isBoxOpen]);

  const handlePagination = (selectedPage) => {
    setCurrentPage(Number(selectedPage));
  };

  const handleRowSelection = (event) => {
    setRowsNumber(Number(event.currentTarget.value));
    setCurrentPage(1);
  };

  const handleDashboardSelection = (event, selectedDashboard) => {
    console.log(event.target.nodeName)
    if (event.target.nodeName === "SPAN" ||  event.target.nodeName === "DIV"){
      setDashboard(selectedDashboard);
      toggleBox();
    }
  };

  const toggleNewDashboardModal = (event) => {
    setNewDashboardModal(prev => !prev);
  }

  return (
    <div className="relative inline-block" ref={menuRef}>
      {/* Renderizando los toasters */}
       {toasts.map((toast) => (
        <Toaster
          key={toast.id}
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => removeToast(toast.id)}
        />
      ))}
      {/* Modal de edicion */}
      <Modal
        isOpen={newDashboardModal}
        onClose={() => {
          setNewDashboardModal(false);
        }}
        title={
          "Información del tablero"
        }
        customType={2}
        colorTitle='bg-bluesens'
        size='w-2/4'
      >
       <DashboardForm saveDashboard={saveDashboard}/>
      </Modal>
      {/* Botón que contiene el ícono y controla la visibilidad del recuadro */}
      <button
        onClick={toggleBox}
        className="p-2 rounded-md hover:bg-gray-200 focus:outline-none"
      >
        <MenuIcon />
      </button>

      {/* Recuadro que se muestra/oculta según el estado */}
      {isBoxOpen && (
        <div className="absolute left-0 mt-2 w-[40rem] max-h-[35rem] p-4 bg-white shadow-lg border rounded-md z-20">
          <div className='flex flex-col h-full'>
            <div className='w-[80%]'>
              <InputForm text={"Buscar"} Icon={() => (<SearchIco />)} />
            </div>
            <div className='flex justify-between items-center'>
              <span className='text-gray-400'>Tableros</span>
              <ButtonForm text={"Nuevo"} enabled={true} action={toggleNewDashboardModal} />
            </div>
            <div className='flex flex-col justify-between w-full h-full py-2'>
              <section className='flex flex-col  overflow-y-auto max-h-[20rem]'>
                {dashboardList.map(dashboard => (
                  <div className='cursor-pointer flex justify-between py-2 hover:bg-gray-100'
                    key={dashboard.name}
                    onClick={(event) => handleDashboardSelection(event,dashboard)}
                  >
                    <div className='basis-3/4 text-wrap'>
                      <span className='max-w-full text-gray-600'>{dashboard.name}</span>
                    </div>
                    <div className='flex gap-3 pr-3'>
                      <button onClick={() => toggleNewDashboardModal()}><EditIco fill='bluesens' /></button>
                      <button><TrashIco fill='red' /></button>
                    </div>
                  </div>
                ))}
              </section>

              {/* Footer */}
              <section id="footer" className='w-100 mt-2  items-center flex '>
                <div className='flex justify-between py-3 w-full'>
                  <div className='mx-3 text-sm  text-gray-500'>{dashboardList.length} total</div>
                  <div className='mx-9'>
                    <ComboBox
                      label={"resultados por página"}
                      options={[5, 10, 15, 20, 25, 30]}
                      onChange={handleRowSelection}
                    />
                  </div>
                  <div className='mx-3'>
                    <Pagination
                      total={dashboardList.length}
                      currentPage={currentPage}
                      maxPaginationPages={rowsNumber}
                      onChange={handlePagination}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardMenu;
