import React, { useRef, useState } from 'react'
import HeadBar from './dashboard/HeadBar'
import DashboardSpace from '../../../components/DashboardSpace'
import Modal from '../../../components/Modal'
import NewWidget from './dashboard/NewWidget'
import { WidgetProvider } from '../../../providers/WidgetContext'


export default function DashboardSection() {
  const [showNewWidget, setShowNewWidget] = useState(false);
  const toggleNewWidgetModal = (event) => {
    setShowNewWidget(prev => !prev);
  }

  return (
    <WidgetProvider>
      <div className='w-full h-full overflow-y-scroll bg-graysens'>
        {/* Modal de edicion */}
        <Modal
          isOpen={showNewWidget}
          onClose={() => {
            setShowNewWidget(false);
          }}
          title={
            "Añadir nuevo Widget"
          }
          customType={2}
          colorTitle='bg-bluesens'
          size='w-fit'
        >
          <NewWidget  toggleNewWidgetModal={toggleNewWidgetModal} />
        </Modal>

        <HeadBar  toggleNewWidgetModal={toggleNewWidgetModal}/>
        <div className='pt-3'>
          <DashboardSpace>
          </DashboardSpace>
        </div>
        
      </div>
    </WidgetProvider>  
  )
}
