import React, { useState, useEffect } from "react";
import TextInput from "../../../components/TextInput";

import { getAllPermissions } from "../../../services/apiPermissions";
import { getAllRoles } from "../../../services/apiRoles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext";

function RoleForm({ onSubmit, role }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [name, setName] = useState(role?.name || "");
  const [description, setDescription] = useState(role?.description || "");
  const [currentStage, setCurrentStage] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const totalStages = 2;
  const stageTitles = ["Información General", "Selección de Permisos"];
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(
    role?.permissionIds || []
  );

  const [selectAll, setSelectAll] = useState(false);

  const [importMode, setImportMode] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [expandedRoleId, setExpandedRoleId] = useState(null);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedPermissions(
      selectAll ? [] : permissions.map((perm) => perm._id)
    );
  };

  useEffect(() => {
    async function fetchPermissionsAndRoles() {
      getAllPermissions(auth?.token).then(permissionsData => {
        setPermissions(permissionsData);
        getAllRoles(auth?.token).then(rolesData => {
          setRoles(rolesData);
        }).catch(error => 
          navigate('/logout')
        );
      }).catch(error => {
        navigate('/logout')
      });
      
    }
    fetchPermissionsAndRoles();
  }, []);

  const handlePermissionToggle = (permissionId) => {
    setSelectedPermissions(
      (prev) =>
        prev.includes(permissionId)
          ? prev.filter((id) => id !== permissionId) // Remove if selected
          : [...prev, permissionId] // Add if not selected
    );
  };
  useEffect(() => {
    if (selectedPermissions.length === permissions.length) {
      setSelectAll(true);
    }
    if (selectedPermissions.length < permissions.length) {
      setSelectAll(false);
    }
  }, [selectedPermissions, permissions]);

  useEffect(() => {
    setIsFormValid(name !== "");
  }, [name]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    let info = { name, description, permissionIds: selectedPermissions };
    onSubmit({ ...role, ...info });
  };

  const handleNext = (event) => {
    event.preventDefault();
    setCurrentStage(currentStage + 1);
  };

  const handlePrevious = () => {
    setCurrentStage(currentStage - 1);
  };

  const handleImportToggle = () => {
    setImportMode(!importMode);
    if (selectedPermissions !== selectedRole?.permissionIds)
      setSelectedRole(null);
  };
  const handleRoleSelect = (roleId) => {
    const role = roles.find((r) => r._id === roleId);
    if (role) {
      setSelectedRole(selectedRole === role ? null : role);
    }
  };
  const handleImport = () => {
    if (selectedRole) {
      setSelectedPermissions(selectedRole.permissionIds);
      setImportMode(false); // Exit import mode
    }
  };

  const toggleAccordion = (roleId) => {
    setExpandedRoleId(expandedRoleId === roleId ? null : roleId);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col gap-y-4"
    >
      {/* Conditional Field Rendering */}
      {currentStage === 0 && (
        <>
          <TextInput
            label="Nombre del Rol"
            id="rol_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={
              roles.find(
                (role) => role.name.toLowerCase() === name.toLowerCase()
              ) && role?.name.toLowerCase() !== name?.toLowerCase()
                ? "Este nombre de rol ya existe"
                : ""
            }
          />
          <TextInput
            label="Descripción del Rol"
            id="rol_description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            isTextArea={true}
          />
        </>
      )}

      {currentStage === 1 && (
        <div>
          <div className="flex justify-end	">
            <button
              type="button"
              className="px-4 py-2 rounded-md bg-bluesens text-white mb-4"
              onClick={handleImportToggle}
            >
              {importMode ? "Volver al Selector" : "Importar"}
            </button>
          </div>

          {importMode ? (
            <div>
              {/* Role Accordions (Custom Implementation) */}
              {roles.map((role) => (
                <div key={role._id} className="border rounded-md mb-2">
                  <div className="flex pr-4 w-full">
                    <button
                      type="button"
                      onClick={() => toggleAccordion(role._id)}
                      className={`flex justify-between px-4 py-2 text-sm font-medium text-left text-gray-900 rounded hover:bg-gray-200 focus:outline-none ${
                        expandedRoleId === role._id && "bg-gray-200"
                      }`}
                    >
                      <img
                        className="w-4 h-4 inset-x-0 bottom-0"
                        alt="arrow-drop-down"
                        src={`${process.env.PUBLIC_URL}/svg/arrow_drop_down.svg`}
                      />
                    </button>
                    <div className="flex w-full justify-between items-center	px-2">
                      <div>{role.name}</div>
                      <input
                        type="checkbox"
                        checked={selectedRole === role}
                        onChange={() => handleRoleSelect(role._id)}
                      />
                    </div>
                  </div>

                  {(expandedRoleId === role._id || selectedRole === role) && (
                    <div className="px-4 pt-4 pb-2 text-sm text-gray-500">
                      {role.permissionIds.map((permId) => {
                        const permission = permissions.find(
                          (p) => p._id === permId
                        );
                        return permission ? (
                          <li className="list-none border-b" key={permId}>
                            {permission.name}
                          </li>
                        ) : (
                          <li className="list-none border-b" key={permId}>
                            Unknown Permission
                          </li>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}

              <button
                type="button"
                onClick={handleImport}
                className="px-4 py-2 rounded-md bg-bluesens text-white mt-4 disabled:bg-gray-200"
                disabled={!selectedRole}
              >
                Importar Permisos
              </button>
            </div>
          ) : (
            <div className="">
              <div className="flex justify-between">
                <div className="text-gray-500 mb-4">
                  {selectedPermissions.length} items seleccionados
                </div>
                <div className="mb-4">
                  <input
                    type="checkbox"
                    id="selectAllPermissions"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <label htmlFor="selectAllPermissions" className="ml-2">
                    Seleccionar Todos
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                {permissions.map((permission) => (
                  <button
                    key={permission._id}
                    type="button"
                    onClick={() => handlePermissionToggle(permission._id)}
                    className={`px-4 py-2 rounded-md ${
                      selectedPermissions.includes(permission._id)
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {permission.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-bluesens bg-blue-200">
              Paso {currentStage + 1}
            </span>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full">
              {stageTitles[currentStage]}
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-bluesens">
              {Math.round(((currentStage + 1) / totalStages) * 100)}%
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div
            style={{ width: `${((currentStage + 1) / totalStages) * 100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-bluesens"
          ></div>
        </div>
      </div>

      {/* Navigation Buttons */}
      {!importMode && (
        <div className="flex flex-col justify-between">
          <div className="w-full flex flex-row justify-between items-center">
            <button
              type="button"
              onClick={handlePrevious}
              className="mt-4 bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded disabled:opacity-0 disabled:pointer-events-none"
              disabled={currentStage === 0}
            >
              Previo
            </button>
            <div className="mt-4">
              <p className="text-gray-500">Paso {currentStage + 1} de 2</p>
            </div>
            {currentStage !== 1 ? (
              <button
                type="button"
                onClick={handleNext}
                id="next-btn-role-form"
                className={`mt-4 bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded disabled:opacity-0 disabled:pointer-events-none ${
                  currentStage === 0 ? "disabled:opacity-50" : ""
                } 
                `}
                disabled={
                  !isFormValid ||
                  (roles.find(
                    (role) => role.name.toLowerCase() === name.toLowerCase()
                  ) &&
                    role?.name.toLowerCase() !== name?.toLowerCase())
                }
              >
                Siguiente
              </button>
            ) : (
              <button
                type="submit"
                id="submit-btn-role-form"
                className={`mt-4 bg-bluesens text-white font-bold hover:scale-125 transition-transform py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
                disabled={!isFormValid}
              >
                <span className="animate-pulse">Finalizar</span>
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
}

export default RoleForm;