import React, { useState } from 'react'
import InputForm from '../../../../components/InputForm'
import ButtonForm from '../../../../components/ButtonForm'
import AddIco from '../../../../components/icons/AddIco'
import TrashIco from '../../../../components/icons/TrashIco'
import CheckIco from '../../../../components/icons/CheckIco'
import DatePicker from '../../../../components/Datepicker'

const config = [
  {"key": "batch", "alias": "Lote", "datetype": null},
  {"key": "supplier", "alias": "Proveedor", "datetype": null},
  {"key": "weight", "alias": "Peso", "datetype": "numeric"},
  {"key": "date", "alias": "Fecha", "datetype": "date"},
  {"key": "mp", "alias": "Materia prima", "datetype": "dictarray"},
]

const InputList = ({ initialItems = [], onChange }) => {
  const [items, setItems] = useState(initialItems);

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, { key: '', value: '', units: '' }]);
    onChange([...items, { key: '', value: '', units: '' }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <div className="relative w-full h-full rounded-md border-2 border-gray-300 my-5">
      <label
        className="text-gray-500 duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:left-4 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:font-medium absolute"
      >
        Prueba
      </label>
      <div className="w-full min-h-10 p-3">
        {items.length === 0 ? (
          <div className="flex w-full h-full items-center my-3 mx-auto">
            <span className="my-auto mx-auto">No hay elementos en la lista</span>
          </div>
        ) : (
          items.map((item, index) => (
            <div key={index} className="flex items-center gap-5">
              <InputForm text={"key"} placeholder={"Key"} value={item.key} onChange={(e) => handleInputChange(index, 'key', e.target.value)}/>
              <InputForm text={"Value"} placeholder={"Value"} value={item.value} onChange={(e) => handleInputChange(index, 'value', e.target.value)}/>
              <InputForm text={"Units"} placeholder={"Units"} value={item.units} onChange={(e) => handleInputChange(index, 'units', e.target.value)}/>
              
              <button
                className='-mt-5'
                onClick={() => handleRemoveItem(index)}
              >
                <TrashIco fill='red' />
              </button>
            </div>
          ))
        )}
        <div className="flex w-full justify-center ">
          <ButtonForm
            text="Agregar campo"
            Icon={() => <AddIco fill="white" />}
            enabled={true}
            action={handleAddItem}
          />
        </div>
      </div>
    </div>
  );
};

function AddEditRegisters() {
  return (
    <div className='flex flex-col gap-2 h-[35rem] relative overflow-auto'>
      <div className=' flex flex-col flex-grow gap-2'>
        <div className='w-full flex flex-col'>
          {config.map( element => (
            <>
              { element.datetype === "date" && (
                <DatePicker text={element.alias || element.key} />
              )}
              { element.datetype === "numeric" && (
                <div className='w-full flex gap-5'>
                  <div className='basis-1/4'>
                    <InputForm text={element.alias || element.key} type={"number"}/>
                  </div>
                  <div className='basis-1/4'>
                    <InputForm text={"unidades"} />
                  </div>      
                </div>
              )}
              { element.datetype === "dictarray" && (
                <InputList onChange={ (list) => console.log(list)}/>
              )}
              { !element.datetype && (
                <InputForm text={element.alias || element.key} />
              )}
            
            </>
            
          ))}
        </div>
      </div>
      {/* Span fijo al final del contenedor */}
      <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
        <div className='flex w-full justify-end'>
            <button className=''>
              <CheckIco width='50px' height='50px'/>
            </button>
        </div>
      </div>
          
    </div>
    
  )
}

export default AddEditRegisters