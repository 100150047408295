import React from "react";
import "../styles/navbar.css";
import { NavLink } from "react-router-dom";
import DropdownMenu from "../components/DropdownMenu";

export default function Navbar() {
  return (
    <div className="  w-full shadow-custom">
      <div className=" mx-auto my-0 px-[8px] max-w-[1400px]">
        <div className="flex items-center">
          <div className=" float-left h-12 p-2 mx-2">
            <img
              className="h-full w-auto max-w-full"
              alt="logo"
              src={`${process.env.PUBLIC_URL}/images/logos/sens.png`}
            />
          </div>
          <ul className="flex flex-wrap list-none relative p-0 m-0 ">
            <DropdownMenu
              label="Datos"
              icon={`${process.env.PUBLIC_URL}/svg/dashboardsIco.svg`}
              options={[
                { to: "/dashboard", label: "Dashboard" },
                { to: "/processes", label: "Procesos" },
   
              ]}
            />
           
            <DropdownMenu
              label="Usuarios y Roles"
              icon={`${process.env.PUBLIC_URL}/svg/usersIco.svg`}
              options={[
                { to: "/usuarios", label: "Usuarios" },
                { to: "/roles", label: "Roles" },
                { to: "/permisos", label: "Permisos" },
              ]}
            />
            <NavLink
              to="/dispositivos"
              className={({ isActive }) =>
                `flex gap-1 items-center px-6 py-3 cursor-pointer border-b-[2.5px] ${
                  isActive
                    ? "border-bluesens text-gray-700"
                    : "border-transparent text-gray-700"
                }`
              }
            >
              <img
                className="w-4 h-4"
                alt="dispositivos"
                src={`${process.env.PUBLIC_URL}/svg/devicesIco.svg`}
              />
              Dispositivos
            </NavLink>
            <NavLink
              to="/organizaciones"
              className={({ isActive }) =>
                `flex gap-1 items-center px-6 py-3 cursor-pointer border-b-[2.5px] ${
                  isActive
                    ? "border-bluesens text-gray-700"
                    : "border-transparent text-gray-700"
                }`
              }
            >
              <img
                className="w-4 h-4"
                alt="organizaciones"
                src={`${process.env.PUBLIC_URL}/svg/organizationIco.svg`}
              />
              Organizaciones
            </NavLink>
          </ul>
          <span className="flex flex-row flex-1"></span>
          <div className="mx-4">
            <select>
              <option> Español</option>
            </select>
          </div>
          <div className="p-0 m-0 inline-flex items-center">
            <button className="topbar-button-right mr-1 img-button mat-icon-button">
              <span className="mat-button-wrapper">
                <img
                  src={`${process.env.PUBLIC_URL}/images/icons/userDefault.png`}
                  alt="profile"
                />
              </span>
              <div className="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
              <div className="mat-button-focus-overlay"></div>
            </button>
            <label className="pl-0 pt-0 pb-0 m-0 pr-2">
              psanchez@senscloud.io
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
