import React from 'react'

function SkipNextIco({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}
    >
      <path d="M660-240v-480h80v480h-80Zm-440 0v-480l360 240-360 240Z"/>
    </svg>
  )
}

export default SkipNextIco