import React, { useEffect, useState } from 'react'

//icons
import TrashIco from './icons/TrashIco'
import EditIco from './icons/EditIco'
import SkipNextIco from './icons/SkipNextIco'
import SkipPreviousIco from './icons/SkipPreviousIco'
import ArrowForward from './icons/ArrowForward'
import ArrowBack from './icons/ArrowBack'

import ComboBox from './ComboBox'



function Pagination({total, currentPage, maxPaginationPages, onChange}) {
  const totalPages = Math.ceil(total/maxPaginationPages);
  function getPagination(c, m, g) {
    console.log(c,m,g)
    // Si el número máximo de números a mostrar es mayor o igual al total de páginas, mostramos todas las páginas
    if (g >= m) {
      return Array.from({ length: m }, (_, i) => i + 1);
    }
    let start, end;
    // Si la página actual está en el principio
    if (c <= Math.floor(g / 2)) {
      start = 1;
      end = g;
    } 
    // Si la página actual está cerca del final
    else if (c + Math.floor(g / 2) >= m) {
      start = m - g + 1;
      end = m;
    } 
    // Si la página actual está en el medio
    else {
      start = c - Math.floor(g / 2);
      end = c + Math.floor(g / 2);
    }
    // Generamos el arreglo de números de página
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  let arrayPages = getPagination(currentPage,totalPages , maxPaginationPages)

  return (
    <div className='flex gap-1'>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(1)}>
        <SkipPreviousIco fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== 1 ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage-1 <= 1 ? 1: currentPage-1)}>
        <ArrowBack height='16px' width='16px' fill={currentPage !==1 ? "gray" : undefined}/>
      </button>
      {arrayPages.map((element,index)=> (
        <button key={index}
          className={`mx-0.5 rounded-md min-w-6 h-6 ${ element === currentPage ? 'bg-bluesens text-white' : 'bg-white hover:bg-graysens'  }`} value={element} onClick={event => onChange(event.target.value)}
        >
          {element}
        </button>
      ))}
      
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(currentPage+1 >= totalPages? totalPages: currentPage+1)}>
        <ArrowForward height='16px' width='16px' fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
      <button className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${ currentPage !== totalPages ? "hover:bg-graysens": "cursor-not-allowed"}`} onClick={event => onChange(totalPages)}>
        <SkipNextIco fill={currentPage !==totalPages ? "gray" : undefined}/>
      </button>
    </div>
  )
}
function GenericTable({data=[], actions=[]}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [registers, setRegisters] = useState([]);
  const [rowsNumber, setRowsNumber] = useState(10);

  const refreshTable = () => {
    let startIndex = (currentPage-1)*rowsNumber
    let endIndex = (startIndex+rowsNumber)
    setRegisters(data.body.slice(startIndex,endIndex));
    console.log(registers);
  }

  const handlePagination = (selectedPage) => {
    setCurrentPage(Number(selectedPage));
  }

  const handleRowSelection = (event) => {
    setRowsNumber(Number(event.currentTarget.value));
    setCurrentPage(1);
  }
   useEffect( () => {
    refreshTable();
   }, [rowsNumber, currentPage]);
  return (
    <div className="overflow-x-auto mb-5">
      <div className="min-w-full shadow-md  border">
        {/* Header */}
        <div
          className="grid text-gray-500 font-semibold p-3 border-b border-gray-300 shadow"
          style={{ gridTemplateColumns: `repeat(${data.header.length}, minmax(0, 1fr))` }}
        >
          {data.header.map((headerItem, index) => (
            <div key={index} className="p-2 text-xs text-center">
              {headerItem}
            </div>
          ))}
        </div>
        {/* Body */}
        <div>
          {registers.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="grid px-3 py-1 hover:bg-graysens"
              style={{ gridTemplateColumns: `repeat(${data.header.length}, minmax(0, 1fr))` }}
            >
              {data.header.map((headerItem, colIndex) => (
                <div key={colIndex} className="p-2 text-center">
                  {headerItem === 'ACCIONES' ? (
                    <div className='flex items-center justify-center gap-4 '>
                      {
                        row[headerItem].map((action, actionIndex) => {
                          if (action === "EDIT") {
                            return (
                            <div key={actionIndex} id={rowIndex} className='cursor-pointer' onClick={actions[action]}>
                              <EditIco fill='bluesens'/>
                            </div>)
                          }
                          if (action === "DELETE") {
                            return (
                            <div key={actionIndex} id={rowIndex} className='cursor-pointer' onClick={actions[action]}>
                              <TrashIco height="18px" fill='red'/>
                            </div>)
                          }
                        })
                      }
                    </div>
                    
                  ) : (
                    row[headerItem] ?  row[headerItem] : "--"
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Footer */}
        <section id="footer" className='w-100 shadow-md border-t items-center flex '>
          <div className='flex justify-between py-3 w-full'>
            <div className='mx-3 text-sm  text-gray-500'>{data.total} total</div>
            <div className='mx-9'>
              <ComboBox
                label={"resultados por página"}
                options={[5,10,15,20,25,30]}
                onChange={handleRowSelection}
              />
            </div>
            <div className='mx-3'>
              <Pagination
                total={data.total}
                currentPage={currentPage}
                maxPaginationPages={rowsNumber}
                onChange={handlePagination}
              />
            </div>
          </div>
      </section>
      </div>
    </div>
  )
}

export default GenericTable