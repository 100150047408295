import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";

import { getVariableById, getVariableByName, updateVariable, deleteVariable } from "../../../services/apiVariables";
import { formatDate } from "../../utils.js";
import { useAuth } from "../../../providers/AuthContext.js";


function VariableDetails() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { variableId } = useParams();
  const [variable, setVariable] = useState(null);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [unity, setUnity] = useState("");
  const [type, setType] = useState("");


  const [errorMessageName, setErrorMessageName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const typeOptions = ["General", "GPS"];

  useEffect(() => {
    async function fetchVariable(variableId) {
      getVariableById(variableId, auth?.token).then(variable => {
        console.log(variable)
        setVariable(variable);
        setName(variable?.name);
        setAlias(variable?.alias);
        setType(variable?.type);
        setUnity(variable?.unity)
      }).catch(error => 
        navigate('/logout')
      );
      
    }
    fetchVariable(variableId);
  }, [variableId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDeleteClick = async () => {
    try {
       deleteVariable(variable).then(response => {
        setSuccessMessage("Variable eliminada exitosamente!");
        setShowSuccessModal(true);
      }).catch(error => 
        navigate('/logout')
      );
      

      setTimeout(() => {
        navigate(-1);
      }, 1000);

    } catch (error) {
      setErrorMessage(
        "Error al eliminar variable. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    async function validateName() {
      if (name) {
        getVariableByName(name, auth?.token).then(found_variable => {
          const exist = typeof found_variable !== "undefined";
          if (!exist || name.toLowerCase() === variable?.name.toLowerCase())
            setErrorMessageName("");
          else {
            setErrorMessageName("Este nombre de variable ya esta en uso");
          }
        }).catch(error =>
          navigate('/logout')
        );
        
      } else {
        setErrorMessageName("");
      }
    }
    validateName();
  }, [name, variable]);

  useEffect(() => {
    setIsFormValid(name !== "" && !errorMessageName);
  }, [name, errorMessageName]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let info = { name, type, alias, unity };
    try {
      updateVariable({ ...variable, ...info }, auth?.token).then(response => {
        setSuccessMessage("Variable editada exitosamente!");
        setShowSuccessModal(true);
      }).catch(error => 
        navigate('/logout')
      );
      
    } catch (error) {
      setErrorMessage(
        "Error al editar variable. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        setSuccessMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (showErrorModal) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showErrorModal]);

  if (!variable) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col">
      <div className="flex justify-between">
        <button
          onClick={handleBackClick}
          className="btn btn-secondary flex items-center transform hover:-translate-x-2 transition duration-300"
        >
          <img
            src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
            alt="Botón de Volver"
            className="h-5 w-5"
          />
          Regresar
        </button>
        <button
          onClick={handleDeleteClick}
          className="btn btn-secondary flex items-center hover:scale-75"
          title="Eliminar Variable"
        >
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt=" Botón de Eliminar Variable"
            className="h-5 w-5"
          />
        </button>
      </div>
      <div className="p-4 flex">
        <div className="flex flex-col mb-4 w-1/3 gap-4 bg-white rounded-lg shadow p-4">
          <h2 className="text-2xl font-semibold text-center">Información de la Variable</h2>
          <TextInput
            label="Nombre"
            id="variable_name"
            value={name}
            onChange={(e) => setName(e.target.value.trim().replace(/\s/g, "_"))}
            error={errorMessageName}
          />
          <TextInput
            label="Alias"
            id="variable_alias"
            value={alias}
            onChange={(e) => setAlias(e.target.value.trim().replace(/\s/g, "_"))}
          />
          <TextInput
            label="Última actividad"
            id="variable_lastActivity"
            value={formatDate(variable.lastActivity)}
            enabled={false}
            AppendClassName="bg-gray-100 cursor-not-allowed"
          />
          <TextInput
            label="Unidad"
            id="variable_unity"
            value={unity}
            onChange={(e) => setUnity(e.target.value.trim().replace(/\s/g, "_"))}
          />
          <SelectInput
            label="Tipo"
            id="device_type"
            options={typeOptions.slice(1)}
            value={type}
            onChange={(e) => setType(e.target.value)}
            placeholder={typeOptions[0]}
          />
          <button
            type="submit"
            id="submit-btn-role-form"
            className={`bg-bluesens text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            <span className="animate-pulse">Guardar</span>
          </button>
        </div>
        <div className="flex flex-col text-center w-2/3 p-4">
          <h2 className="text-xl">Gráficas</h2>
          <div className="grid grid-cols-4 gap-4 mt-4 p-4 bg-white rounded-lg shadow-md">
            <div className="p-4 flex flex-col items-center border-dashed border-4 border-bluesens rounded-xl">
              
              <p className="text-xl font-semibold mb-2">
                Añadir Gráfica
              </p>
            </div>

          </div>
        </div>
      </div>
      <>
        {/* Success Modal */}
        <Modal
          title="Éxito"
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          colorTitle="bg-green-300"
          size="w-fit"
          showCloseButton={false}
        >
          <div className="text-center">
            <p>{successMessage}</p>
          </div>
        </Modal>
        {/* Error Modal */}
        <Modal
          title="Error"
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          colorTitle="bg-red-300"
          size="w-fit"
          showCloseButton={false}
        >
          <div className="text-center">
            <p>{errorMessage}</p>
          </div>
        </Modal>
      </>
    </div>
  );
}

export default VariableDetails;
