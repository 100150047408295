import React, { useState } from 'react'
import InputForm from '../../../components/InputForm'
import SelectionForm from '../../../components/SelectionForm'
import ButtonForm from '../../../components/ButtonForm'
import Button from '../../../components/Button'
import SettingsIco from '../../../components/icons/SettingsIco'
import SearchIco from '../../../components/icons/SearchIco'
import AddCircle from '../../../components/icons/AddCircle'
import CheckIco from '../../../components/icons/CheckIco'
import TrashIco from '../../../components/icons/TrashIco'
import GenericTable from '../../../components/GenericTable'
import CollapsibleSection from '../../../components/CollapsibleSection'
import AddEditRegisters from './widgets/AddEditRegisters'
import Modal from '../../../components/Modal'

let data = {
  header: [
    "Clave",
    "Alias",
    "Tipo de dato",
    "ACCIONES"
  ],
  body: [
    {"Clave": "batch", "Alias": "Lote 1", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 2", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 3", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 4", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 5", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 6", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 7", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 8", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "weight", "Alias": "Peso", "Tipo de dato": "numerico", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "date", "Alias": "Fecha", "Tipo de dato": "fecha", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 9", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 10", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 11", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 12", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 13", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 14", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 15", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 16", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 17", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 18", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 19", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 20", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 21", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 22", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 23", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 24", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 25", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 26", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 27", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 28", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 29", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 30", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 31", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    {"Clave": "batch", "Alias": "Lote 32", "Tipo de dato": "--", "ACCIONES": ["EDIT","DELETE"]},
    
  ],
  total: 34
}

const config = [
  {"key": "batch", "alias": "Lote", "datetype": null},
  {"key": "supplier", "alias": "Proveedor", "datetype": null},
  {"key": "mp", "alias": "Materia prima", "datetype": "dictarray"},
  {"key": "weight", "alias": "Peso", "datetype": "numeric"},
  {"key": "date", "alias": "Fecha", "datetype": "date"},
]

function RegistersSection() {
  const [tableSettings, setTableSettings] = useState([]);
  const [showSettingsForm, setShowSettingsForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  const toggleSettingsModal = (event) => {
    setShowSettingsForm(true);
  }
  const toggleRegisterModal = (event) => {
    setShowRegisterForm(true);
  }

  return (
    <div className='w-full h-full overflow-y-scroll bg-graysens'>
      {/* Modal nuevo registro */}
      <Modal
        isOpen={showRegisterForm}
        onClose={() => {
          setShowRegisterForm(false);
        }}
        customType={2}
        size='w-2/4'
        colorTitle='bg-bluesens'
        title={"Nuevo Registro"}
      >
        <AddEditRegisters />
      </Modal>
      {/* Modal de configuracion de tabla */}
      <Modal
        isOpen={showSettingsForm}
        onClose={() => {
          setShowSettingsForm(false);
        }}
        customType={2}
        colorTitle='bg-bluesens'
        title={"Configurar tabla"}
      >
        <div className='flex flex-col gap-2 h-[35rem] relative overflow-auto'>
          <div className=' flex flex-col flex-grow gap-2'>
          <CollapsibleSection title={"Columnas a mostrar"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' open={true} border={false}>
            <div className="px-4 grid grid-cols-[2.5rem_1fr_auto] gap-2 w-full">
              <div className="flex items-center justify-center">Orden</div>
              <span className="text-left text-sm">Nombre de la columna</span>
              <div></div>
              {tableSettings.map((element, index) => (
                <React.Fragment key={index}>
                  <div className="flex items-center justify-center">{index + 1}</div>
                  <span className="text-left">{element.alias || element.key}</span>
                  <button onClick={() => {
                    let tempSet = [...tableSettings]
                    tempSet.splice(index,1)
                    setTableSettings(tempSet)
                    }}>
                    <TrashIco fill='red' />
                  </button>
                  
                </React.Fragment>
              ))}
            </div>
          </CollapsibleSection>

          <CollapsibleSection title={"Columnas ocultas"} titleBgColor='bg-lightblue' titleTextColor='text-bluesens' open={true} border={false}>
            <div className="px-4 grid grid-cols-[2.5rem_1fr_auto] gap-2 w-full">
              {config
                .filter(element => !tableSettings.some(setting => setting.key === element.key))
                .map((element, index) => (
                  <React.Fragment key={index}>
                    <div className="flex items-center justify-center"></div>
                    <span className="text-left">{element.alias || element.key}</span>
                    <button onClick={() => {setTableSettings([...tableSettings, element])}}>
                      <AddCircle />
                    </button>
                  </React.Fragment>
                ))}
            </div>
          </CollapsibleSection>
          </div>
          {/* Span fijo al final del contenedor */}
          <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
            <div className='flex w-full justify-end'>
                <button className=''>
                  <CheckIco width='50px' height='50px'/>
                </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className='w-[95%] mx-auto h-full bg-white'>
        <div className='px-10 w-full pt-5'>
          <section id='filters' className='w-full flex items-center'>
            
            <div className='flex basis-2/4 gap-2' >
              <div className='w-[70%]'>
                <InputForm text={"Buscar por"} Icon={ () => {return (<SearchIco />)}}/>
              </div>
              <div className='w-[30%]'>
                <SelectionForm text={""} />
              </div>
            </div>
            <div className='flex gap-2 -mt-5 basis-2/4 justify-end'>
              <ButtonForm text={"Nuevo registro"} action={toggleRegisterModal} enabled={true}/>
              <Button text={"Conf. tabla"} Icon={ () => {return (<SettingsIco fill='gray'/>)}} 
              onClick={(event) => toggleSettingsModal()}  />
            </div>
          </section>
          <section>
          <GenericTable
            data={data}
          />
          </section>
        </div>
      </div>
    </div>
  )
}

export default RegistersSection