import React from 'react'

function Button({text, onClick, enabled=true, Icon}) {
  return (
    <button 
      type="submit"
      className={`text-gray-500  bg-graysens  focus:ring-4 focus:outline-none focus:ring-blue-300 rounded text-sm w-full sm:w-auto px-4 py-2 text-center ${!enabled ? "cursor-pointer": ""}`}
      onClick={onClick}
      disabled={!enabled}
    >
      <div className='flex gap-1 mx-auto justify-between items-center'>
        <span className='text-gray-500'>{text}</span>
        {Icon && (<Icon />)}
      </div>
      
      
    </button>
  )
}

export default Button