import React from 'react'

function ArrowBack({height="24px", width="24px", viewBox="0 -960 960 960", fill="#e8eaed"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={viewBox} 
      width={width}
      fill={fill}
    >
      <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/>
    </svg>
  )
}

export default ArrowBack