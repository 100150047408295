export function elapsedTime(lastUpdate) {
    const now = new Date();
    const elapsedTimeMs = now - new Date(lastUpdate);

    const seconds = Math.floor(elapsedTimeMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    let elapsedTimeString = "";

    if (years > 0) {
        elapsedTimeString += `${years} año${years > 1 ? "s" : ""} `;
        if (months % 12 > 0) {
            elapsedTimeString += `y ${months % 12} mes${months % 12 > 1 ? "es" : ""}`;
        }
    } else if (months > 0) {
        elapsedTimeString += `${months} mes${months > 1 ? "es" : ""} `;
        if (days % 30 > 0) {
            elapsedTimeString += `y ${days % 30} día${days % 30 > 1 ? "s" : ""}`;
        }
    } else if (weeks > 0) {
        elapsedTimeString += `${weeks} semana${weeks > 1 ? "s" : ""} `;
        if (days % 7 > 0) {
            elapsedTimeString += `y ${days % 7} día${days % 7 > 1 ? "s" : ""}`;
        }
    } else if (days > 0) {
        elapsedTimeString += `${days} día${days > 1 ? "s" : ""} `;
        if (hours % 24 > 0) {
            elapsedTimeString += `y ${hours % 24} hora${hours % 24 > 1 ? "s" : ""}`;
        }
    } else if (hours > 0) {
        elapsedTimeString += `${hours} hora${hours > 1 ? "s" : ""} `;
        if (minutes % 60 > 0) {
            elapsedTimeString += `y ${minutes % 60} minuto${minutes % 60 > 1 ? "s" : ""
                }`;
        }
    } else if (minutes > 0) {
        elapsedTimeString += `${minutes} minuto${minutes > 1 ? "s" : ""} `;
    } else {
        elapsedTimeString = "pocos segundos";
    }

    return elapsedTimeString;
}

export function formatDate(stringDate) {
    return new Date(stringDate)
    .toISOString()
    .replace("T", " ")
    .slice(0, -5)
}