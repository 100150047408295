import React, { useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonForm from '../../../components/ButtonForm'
import Spinner from '../../../components/Spinner';
import axios from 'axios';
import { useAuth } from '../../../providers/AuthContext';
import { useNavigate } from 'react-router-dom';

const FRONT_BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // || 'http://localhost:4000';

export default function LoginForm() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const doLogin = async () => {
    let formTemplate = {}
    if (user === "") {
      formTemplate.userError = "Usuario requerido"
    }
    if (password === "") {
      formTemplate.passError = "Contraseña requerida"
    }

    if (Object.keys(formTemplate).length > 0) {
      setFormState(formTemplate);
      return;
    }

    setLoading(true);
    try {
      console.log('Attempting login with URL:', `${FRONT_BACKEND_URL}/login`);
      console.log('Login data:', { user, password });
      const response = await axios.post(`${FRONT_BACKEND_URL}/login`, {
        username: user,
        password: password
      });

      console.log(response);
      if (response.status === 200 && response.data && response.data.token) {
        login(response.data.token);
        setFormState({});
        navigate("/dashboard");
      } else {
        throw new Error('Respuesta inválida del servidor');
      }
    } catch (error) {
      console.error('Error durante el inicio de sesión:', error);
      formTemplate.formError = error.response?.data?.message || 'Error al iniciar sesión. Por favor, intente nuevamente.';
      setFormState(formTemplate);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full mx-auto p-5 lg:p-11">
      {!loading && (
        <>
          <InputForm
            type="text" id="username" text="Nombre de usuario"
            onChange={(event) => setUser(event.target.value)}
            enabled={!loading}
          />
          <InputForm type="password" id="password" text="Contraseña"
            onChange={(event) => setPassword(event.target.value)}
            enabled={!loading}
          />

          {formState.formError && (
            <div className='relative'>
              <span className='absolute -top-4 text-red-600'>{formState.formError}</span>
            </div>
          )}
          <div className='flex flex-col pt-3 gap-3 md:pt-0 md:gap-0 md:flex-row md:justify-between items-center'>
            <a href='/recovery'>¿Olvidó la contraseña?</a>
            <ButtonForm
              text={"Iniciar Sesión"}
              enabled={user !== "" && password !== ""}
              action={doLogin}
            >
            </ButtonForm>
          </div>
          <div className='flex justify-end pt-[35px]'>
            <select>
              <option>Español</option>
            </select>
          </div>
        </>
      )}

      {loading && (
        <>
          <div className='relative w-full '>
            <div className='relative'>
              <Spinner />
            </div>
          </div>
          <div className='mx-auto flex justify-center w-full mt-5'>
            <span className='text-md'>Iniciando sesión...</span>
          </div>
        </>
      )}
    </div>
  )
}