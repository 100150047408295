import React from 'react'

function MenuIcon({height="24px", width="24px", viewBox="0 -960 960 960", fill="#020317"}) {
  return (
    <svg 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}
    >
      <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
    </svg>
  )
}

export default MenuIcon