import React from 'react'
import InputForm from '../../../components/InputForm'
import SelectionForm from '../../../components/SelectionForm'
import ButtonForm from '../../../components/ButtonForm'
function AddEditParameter({parameter}) {

  let inputs = {}
  if (parameter){
    inputs = {
      name : parameter.Clave,
      alias: parameter.Alias,
      type: parameter["Tipo de dato"]
    }
  }
  console.log(inputs)
  return (
    <div className='w-100 mx-auto flex flex-col'>
      <InputForm text={"Nombre de parámetro"} value={inputs.name}/>
      <InputForm text={"Alias"} value={inputs.alias}/>
      <SelectionForm 
        options={[ "numerico","fecha", "lista"]}
        text={"Tipo"} 
        id={"type"}
        value={inputs.type}
        />
      <div className='mx-auto mt-16'>
        <ButtonForm text={"Guardar"} enabled={true}/>
      </div>
      
    </div>
  )
}

export default AddEditParameter