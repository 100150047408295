import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { elapsedTime } from "../../utils.js";

function VariableCard({ variable, appendClass = ' '}) {
    const [elapsedTimeString, setElapsedTimeString] = useState(
        elapsedTime(variable.lastActivity)
    );

    const navigate = useNavigate();
    const { deviceId } = useParams();

    const handleCardClick = () => {
        navigate(
            `/dispositivo/${deviceId}/variable/${variable._id}`
        );
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newElapsedTimeString = elapsedTime(variable.lastActivity);
            if (
                newElapsedTimeString.includes("minuto") ||
                newElapsedTimeString.includes("segundo")
            ) {
                setElapsedTimeString(newElapsedTimeString);
            }
        }, 60000);

        return () => clearInterval(intervalId);
    }, [variable.lastActivity]);

    return (
        <div
            onClick={handleCardClick}
            key={variable._id}
            className={"p-4 flex flex-col items-center justify-center border-dashed border-4 border-bluesens rounded-xl cursor-pointer " + appendClass}
        >
            <h3 className="text-lg font-medium mb-2">{variable.name}</h3>
            <p className="text-gray-600">{variable.alias}</p>
            <p className="text-sm text-gray-500 mt-2">Última Actividad:</p>
            <p>Hace {elapsedTimeString}</p>
        </div>
    );
}

export default VariableCard;
