import React from 'react'
import TitleBar from '../features/processes/components/TitleBar'
import Menu from '../features/processes/components/Menu'
import ConfigSection from '../features/processes/components/ConfigSection';
import RegistersSection from '../features/processes/components/RegistersSection';
import DashboardSection from '../features/processes/components/DashboardSection';
import { useProcesses } from '../providers/ProcessesContext';
import CubeLoader from '../components/CubeLoader';

function ProcessesContainer(props) {
  const {selectedOption, selectedSubOption, fetchingProcess } = useProcesses();
  console.log(selectedOption, selectedSubOption)
  /* const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null); */
  return (
    <div className='flex flex-col w-full h-screen'>
      <div className='min-h-10 '>
        <TitleBar text={"Procesos"} />
      </div>
      <div className='h-full '>
        {fetchingProcess && (
          <div className='w-full h-full flex items-center justify-center'>
            <CubeLoader />
          </div>)}
        {!fetchingProcess && (
          <div className='w-full h-full flex'>
            <section className='w-[15%] flex'>
              <Menu />
            </section>
            <section className='w-[85%] flex'>
              {selectedSubOption === "Configuración" && selectedOption !== "Dashboard" && (
                <ConfigSection />
              )}
              {selectedSubOption === "Registros" && selectedOption !== "Dashboard" && (
                <RegistersSection />
              )}
              {selectedOption === "Dashboard" && (
                <DashboardSection />
              )}
            </section>
          </div>
        )}
        
      </div>
      {/* <div className='basis-10'>
        <TitleBar text={"Procesos"} />
      </div>
      <div className='h-full bg-cyan-900'>
        <div className='w-full h-full flex'>
          <section className='w-[15%] flex'>
            <Menu />
          </section>
        </div>
      </div> */}
      
      
    </div>
  )
}

export default ProcessesContainer