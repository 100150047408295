import React, { useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonForm from '../../../components/ButtonForm'
import Spinner from '../../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


export default function RecoveryForm() {
  const [stage, setStage] = useState("email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const sendCode = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email==="") {
      setError("ingrese un mail");
      return ;
    }
    if (!emailRegex.test(email)) {
      setError("formato incorrecto");
      return ;
    }
    //realizar login al backend
    setLoading(true);
    axios.post( process.env.REACT_APP_BACKEND_URL + '/login/generateRecoveryCode',
    {
      email
    }).then(Response => {
      setLoading(false);
      console.log(Response)
      if (Response.status === 200){
        setStage("verify");
        setError("");
      }
    }).catch(error => {
      setLoading(false);
      console.log(error.response.data.message)
      setError(error.response.data.message); 
    });
    
  }
  const verifyCode = () => {
    //realizar login al backend
    console.log(email,code)
    setLoading(true);
    axios.post(process.env.REACT_APP_BACKEND_URL + '/login/verifyRecoveryCode',
    {
      email,
      code
    }).then(Response => {
      setLoading(false);
      console.log(Response)
      if (Response.status === 200){
        setStage("recovery");
        setError("");
      }
    }).catch(error => {
      setLoading(false);
      console.log(error.response.data.message)
      setError(error.response.data.message); 
    });
    
  }

  const changePass = () => {
    if (newPassword !== password){
      setError("Contraseñas no coinciden");
      return;
    }
    if (password.length < 8){
      setError("Contraseña debe tener minimo 8 caracteres");
      return;
    }
    setLoading(true);
    axios.post(process.env.REACT_APP_BACKEND_URL + '/login/changePasswordByEmail',
    {
      email,
      newPassword: password
    }).then(Response => {
      setLoading(false);
      console.log(Response)
      if (Response.status === 200){
        setStage("finish");
        setError("");
      }
    }).catch(error => {
      setLoading(false);
      console.log(error.response.data.message)
      setError(error.response.data.message); 
    });
  }

  const handleRedirect = () => {
    navigate('/login');
  }

  return (
    <div className='mx-auto flex flex-col w-[90%]'>
      <section className='self-baseline mt-5 mx-auto'>
        <img 
          alt='logo'
          src={`${process.env.PUBLIC_URL}/images/logos/sens.png`}
          width={200}
        />
      </section>
      {stage === "email" && (
        <div className={` flex flex-col transition-transform ${
          stage === "email" ? 'animate-slideInRight' : ''
        }`}>
          <span className='text-center my-4 text-gray-500'>Ingresa el email vinculado a tu cuenta</span>
          <InputForm 
            type="text" id="email" text="Email"
            error={error}
            onChange={(event) => {setEmail(event.target.value); setError("");}}
          />
          {loading && (
            <>
              <div className='relative'>
                <Spinner />
              </div>
            </>
          )}
          {!loading && (
            <ButtonForm 
              text={"Enviar código"}
              action={sendCode}
              enabled={email !== ""}
            />
          )}
          
          <a  className="text-center my-5" href='/login'> o Inicia Sesión</a>
        </div>
      )}
      {stage === "verify" && (
        <div className={` flex flex-col transition-transform ${
          stage !== "email" ? 'animate-slideInRight' : ''
        }`}>
          <span className='text-center my-4 text-gray-500'>Ingresa el código de verificación</span>
          <InputForm 
            type="text" id="code"
            error={error}
            onChange={(event) => {setCode(event.target.value); setError("");}}
            AppendClassName=" text-center"
          />
          {loading && (
            <>
              <div className='relative'>
                <Spinner />
              </div>
            </>
          )}
          {!loading && (
            <ButtonForm 
              text={"Verificar"}
              action={verifyCode}
              enabled={code !== ""}
            />
          )}
          <span onClick={() => {setStage("email"); setError("")}}  className=" cursor-pointer text-center my-5">
            regresar
          </span>
        </div>
      )}
      {stage === "recovery" && (
        <div className={` flex flex-col transition-transform ${
          stage === "recovery" ? 'animate-slideInRight' : ''
        }`}>
          <span className='text-center my-4 text-gray-500'>Ingresa tu nueva clave</span>
          <InputForm 
            type="password" id="pass" text="Contraseña"
            onChange={(event) => {setPassword(event.target.value); setError("");}}
          />
          <InputForm 
            type="password" id="confpass" text="Confirmar contraseña"
            error={error}
            onChange={(event) => {setNewPassword(event.target.value); setError("");}}
          />
          {loading && (
            <>
              <div className='relative'>
                <Spinner />
              </div>
            </>
          )}
          {!loading && (
            <ButtonForm 
              text={"Cambiar clave"}
              action={changePass}
              enabled={password !== "" && newPassword !== ""}
            />
          )}
          
          <span className="my-5">
          </span>
        </div>
      )}
      {stage === "finish" && (
        <div className={"flex flex-col pb-5"}>
          <span className=' text-xl text-center my-4 text-gray-500'>
            Contraseña cambiada exitosamente
          </span>
          <div className='mx-auto'>
            <ButtonForm 
              text={"Iniciar Sesión"}
              enabled={true}
              action={handleRedirect}
            />
          </div>
        </div>
      )}
    </div>
  )
}
