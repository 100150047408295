import React from 'react'

function PieChartIco({height="24px", width="24px", viewBox="0 -0 35 35", fill="#020317"}) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      height={height}
      viewBox={viewBox}
      width={width}
      fill={fill}>
        <g>
	<path fill={fill} d="M17.958,17.041l4.342-15.355c-1.381-0.39-2.837-0.603-4.342-0.603C9.145,1.083,2,8.229,2,17.041
		C2,25.855,9.145,33,17.958,33c8.813,0,15.958-7.145,15.958-15.959c0-0.296-0.01-0.592-0.026-0.885L17.958,17.041z"/>
	<path fill={fill} d="M19.259,15.978l4.729-13.713c0,0,9.93,3.916,9.93,12.427L19.259,15.978z"/>
</g>
    </svg>
  )
}

export default PieChartIco